import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
} from '@mui/material';
import { getPermissionLevel } from '@pn/core/domain/user';
import { getNumberOfIds, WorkspaceItem } from '@pn/core/domain/workspace';
import { LIST_MAX_SIZE, LIST_MAX_SIZE_FREE } from '@pn/core/limits';
import { duplicateWorkspaceItem } from '@pn/core/operations/workspace/crud/duplicateWorkspaceItem';
import { submitWorkspaceItem } from '@pn/core/operations/workspace/crud/submitWorkspaceItem';
import {
  getStyleEditability,
  isEditable,
} from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useMapStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { NavRail } from '@pn/ui/custom-components/nav-rail';
import { NavRailItem } from '@pn/ui/custom-components/nav-rail/NavRailItem';
import { useMenu } from '@pn/ui/hooks/useMenu';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { ForwardSlashIcon } from '@pn/ui/icons';
import { ColorfulStarsIcon } from '@pn/ui/icons/ColorfulStarsIcon';
import {
  EditableTypography,
  useEditableTypographyState,
} from '@pn/ui/inputs/EditableTypography';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { FilterPropertyComponent } from 'src/ui/workspace/components/FilterPropertyComponent';
import { ItemInformation } from 'src/ui/workspace/components/ItemInformation';
import { ListExports } from 'src/ui/workspace/components/ListExports';
import { QueryOptionsComponent } from 'src/ui/workspace/components/QueryOptions';
import { SourceItemSelector } from 'src/ui/workspace/components/SourceItemSelector';
import { DialogModule } from 'src/ui/workspace/dialog-modules';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { CombinedColorPicker } from 'src/ui/workspace/styling/CombinedColorPicker';
import { StyleControlsAccordion } from 'src/ui/workspace/styling/StyleControlsAccordion';
import { StylingAlert } from 'src/ui/workspace/styling/StylingAlert';
import { useStyles } from './WorkspaceItemPanel.styles';
import { WorkspaceItemPanelMenu } from './WorkspaceItemPanelMenu';

type Props = {
  item: WorkspaceItem;
};

export const WorkspaceItemPanel = React.memo(_WorkspaceItemPanel);
function _WorkspaceItemPanel({ item }: Props) {
  const { xsScreen, smScreen } = useScreenSize();
  const { classes } = useStyles();

  const { idsInWorkspace } = useWorkspaceStorage();
  const { user } = useCurrentUserStorage();
  const { mapMode } = useMapStorage();

  const {
    isWorkspaceItemPanelOpen,
    tab,
    setTab,
    openWorkspaceItemPanel,
    closeWorkspaceItemPanel,
    resetCurrentItem,
  } = useWorkspaceItemPanel();
  const { setLibraryRouter } = useLibrary();

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();
  const [isInProgress, setIsInProgress] = React.useState(false);

  const primaryLayer = item.map.layers[0];

  const numberOfIds = React.useMemo(() => getNumberOfIds(item), [item]);
  const isEmptyList = item.itemType === 'list' && numberOfIds === 0;
  const limit =
    getPermissionLevel(user?.userPlan) < 2 ? LIST_MAX_SIZE_FREE : LIST_MAX_SIZE;
  const maxIdsExceeded = item.itemType === 'list' && numberOfIds > limit;

  const nameState = useEditableTypographyState(item.name, item.isTemporary);
  const isEditingDisabled = isInProgress || !isEditable(item, user);
  const isStylingDisabled =
    isInProgress || getStyleEditability(item, user) === 'no_editability';
  const isSubmitButtonDisabled =
    isEditingDisabled ||
    isEmptyList ||
    maxIdsExceeded ||
    isEmpty(nameState.editingValue) ||
    item.itemType === 'annotation';

  const handleRename = () => {
    nameState.setIsEditing(true);
    setTimeout(() => nameState.inputRef.current?.focus(), 0);
  };

  const handleDuplicate = async () => {
    assert(user, 'User must be defined to duplicate a workspace item');

    closeMenu();
    setIsInProgress(true);

    const { duplicatedItem, errorThrown } = await duplicateWorkspaceItem({
      item,
      isInWorkspace: idsInWorkspace.includes(item.id),
      user,
    });

    setIsInProgress(false);

    if (!errorThrown) {
      setLibraryRouter({ section: 'items', folder: 'Personal' });
      openWorkspaceItemPanel({ item: duplicatedItem });
      handleRename();
    }
  };

  const handleSubmit = async () => {
    setIsInProgress(true);

    const { updatedItem, errorThrown } = await submitWorkspaceItem({ item });

    setIsInProgress(false);

    if (errorThrown) return;

    workspaceActions().addToWorkspace(updatedItem.id);
    workspaceActions().select(updatedItem.id);

    closeMenu();
    closeWorkspaceItemPanel(true);
  };

  const handleClose = () => {
    resetCurrentItem();
    closeWorkspaceItemPanel();
  };

  return (
    <Container isOpen={isWorkspaceItemPanelOpen} onClose={handleClose}>
      {!smScreen && (
        <NavRail width={MAIN_TRAY_WIDTH}>
          <NavRailItem
            Icon={tab === 'Content' ? ArticleIcon : ArticleOutlinedIcon}
            label="Content"
            selected={tab === 'Content'}
            onClick={() => setTab('Content')}
          />
          <NavRailItem
            Icon={tab === 'Style' ? AutoAwesomeIcon : ColorfulStarsIcon}
            label="Style"
            selected={tab === 'Style'}
            onClick={() => setTab('Style')}
          />
        </NavRail>
      )}

      <WorkspaceItemPanelMenu
        item={item}
        disabled={isEditingDisabled}
        anchorEl={anchorEl}
        onClose={closeMenu}
        onRename={handleRename}
        onDuplicate={handleDuplicate}
      />

      <Box className={classes.dialogTitleContainer}>
        <DialogTitle className={classes.dialogTitle}>
          Layer
          <ForwardSlashIcon fontSize="medium" color="disabled" />
          <EditableTypography
            singleClickToEdit
            variant="body1"
            classes={{
              typography: classes.editableTypography,
              textField: classes.editableTextField,
            }}
            title={
              isEditingDisabled ? 'Cannot rename this layer' : 'Click to edit'
            }
            disabled={isEditingDisabled || item.itemType === 'annotation'}
            value={item.name}
            onSave={(newValue) =>
              workspaceActions().updateName(item.id, newValue)
            }
            {...nameState}
          />
        </DialogTitle>

        <Box mr={2}>
          <IconButton disabled={isInProgress} onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          {!smScreen && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box px={2}>
        <Divider className={classes.divider} />
      </Box>
      <DialogContent className={classes.dialogContent}>
        {(tab === 'Content' || smScreen) && (
          <>
            <ItemInformation item={item} />

            {!isNil(item.sourceItem) && (
              <Box maxWidth={320}>
                <SourceItemSelector
                  disabled={isEditingDisabled}
                  isSelectorEnabled={
                    item.isTemporary && isEmpty(item.query.filters) // we don't handle filter lists yet
                  }
                  item={item}
                  sourceItem={item.sourceItem}
                />
              </Box>
            )}

            {item.itemType === 'list' && (
              <QueryOptionsComponent
                disabled={isEditingDisabled}
                item={item}
                numberOfIds={numberOfIds}
              />
            )}

            {item.itemType === 'layer' && (
              <FilterPropertyComponent
                item={item}
                filterProperty={item.map.filterProperty}
                isInWorkspace={idsInWorkspace.includes(item.id)}
              />
            )}

            {!xsScreen && item.itemType === 'list' && !item.isTemporary && (
              <ListExports item={item} />
            )}
          </>
        )}

        {(tab === 'Style' || smScreen) && (
          <StylingAlert item={item} user={user} onDuplicate={handleDuplicate} />
        )}

        {(tab === 'Style' || smScreen) &&
          (smScreen ? (
            <Paper variant="outlined" square className={classes.paperContent}>
              {!isNil(primaryLayer) && (
                <CombinedColorPicker
                  item={item}
                  layer={primaryLayer}
                  disabled={isStylingDisabled}
                  updateAll
                />
              )}
            </Paper>
          ) : (
            <StyleControlsAccordion
              item={item}
              disabled={isStylingDisabled}
              mapMode={mapMode}
            />
          ))}

        <DialogModule module={item.module} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {smScreen && (
          <Button disabled={isInProgress} onClick={handleClose}>
            Close
          </Button>
        )}
        {smScreen ? (
          <Button disabled={isSubmitButtonDisabled} onClick={handleSubmit}>
            Save
          </Button>
        ) : (
          <CustomButton
            className={classes.saveButton}
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
          >
            Save
          </CustomButton>
        )}
      </DialogActions>
      {isInProgress ? (
        <LinearProgress className={classes.progressIndicator} />
      ) : (
        <Box height={4} className={classes.progressIndicator} />
      )}
    </Container>
  );
}

function Container(props: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { children, isOpen, onClose } = props;

  const { classes } = useStyles();
  const { xsScreen, smScreen } = useScreenSize();

  if (smScreen) {
    return (
      <Dialog
        fullScreen={xsScreen}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        open={isOpen}
        onClose={onClose}
      >
        {children}
      </Dialog>
    );
  } else {
    return isOpen ? <Box className={classes.container}>{children}</Box> : null;
  }
}
