import { Box, InputAdornment, TextField, ToggleButton } from '@mui/material';
import { GAP, SQ_SIZE } from '@pn/ui/color-picker';
import { ExclusiveToggleButtonGroup } from '@pn/ui/custom-components/ExclusiveToggleButtonGroup';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  toggleButtonGroup: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& > button:last-of-type': {
      width: SQ_SIZE + GAP - 1, // to compensate for the missing right border
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: 'none',
    },
  },
  toggleButton: {
    width: SQ_SIZE + GAP,
    height: SQ_SIZE + GAP,
    padding: 10,
    lineHeight: 1,
  },
  toggleButtonInputBase: {
    height: SQ_SIZE + GAP,
    width: SQ_SIZE * (7 - 4) + GAP * (7 - 4 - 1) + 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '& .MuiInputAdornment-root': {
      minWidth: 17, // fits both "px" and "%"
    },
  },
  toggleButtonInput: {
    textAlign: 'right',
  },
  adornment: {
    minWidth: 20,
  },
}));

type Props = {
  toggles: { value: number; label?: string; icon?: React.ReactNode }[];
  disabled?: boolean;
  value: number;
  units: string;
  onChange: (value: number) => void;
};

export const NumericInputWithToggles = ({
  toggles,
  disabled,
  value,
  units,
  onChange,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Box>
      <ExclusiveToggleButtonGroup
        className={classes.toggleButtonGroup}
        disabled={disabled}
        value={value}
        onChange={(_e, value) => onChange(value)}
      >
        {toggles.map((toggle) => (
          <ToggleButton
            key={toggle.value}
            className={classes.toggleButton}
            value={toggle.value}
          >
            {toggle.icon ?? toggle.label ?? toggle.value}
          </ToggleButton>
        ))}
      </ExclusiveToggleButtonGroup>
      <TextField
        type="number"
        size="small"
        disabled={disabled}
        value={Math.round(value)}
        onChange={(e) => onChange(Math.round(Number(e.target.value)))}
        slotProps={{
          htmlInput: { min: 1 },
          input: {
            classes: {
              root: classes.toggleButtonInputBase,
              input: classes.toggleButtonInput,
            },
            endAdornment: (
              <InputAdornment position="end">{units}</InputAdornment>
            ),
          },
        }}
      />
    </Box>
  );
};
