import { Box, CircularProgress } from '@mui/material';
import { useInitializeMap } from '@pn/core/operations/mapInteractions';
import { useMapStorage } from '@pn/core/storage';
import { PNMap } from '@pn/ui/map/PNMap';
import { usePNThemeContext } from '@pn/ui/theme/PetroNinjaThemeProvider';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { DraggableHandle } from 'src/ui/bottom-table/DraggableHandle';
import { DrawingCanvas } from 'src/ui/drawing/DrawingCanvas';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { MapButtons } from 'src/ui/map/components/map-buttons';
import { MapAlerts } from 'src/ui/map/components/MapAlerts';
import { MapPrintSnapshot } from 'src/ui/map/components/MapPrintSnapshot';
import { MapTerms } from 'src/ui/map/components/MapTerms';
import { PrintComponents } from 'src/ui/map/components/PrintComponents';
import { ProjectLoadingOverlay } from 'src/ui/map/components/ProjectLoadingOverlay';
import { MapEffects } from 'src/ui/map/MapEffects';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  isMainTrayOpen: boolean;
}>()((theme, { isMainTrayOpen }) => ({
  mapContainer: {
    gridArea: 'map',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    zIndex: zIndex(theme).map,
  },
  map: {
    '& .mapboxgl-popup-content': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
    },
    '& .mapboxgl-popup-tip': {
      borderRightColor: theme.palette.primary.main,
    },
    '& .mapboxgl-ctrl-bottom-left': {
      left: isMainTrayOpen ? MAIN_TRAY_WIDTH : 0,
    },
    '& .mapboxgl-ctrl-bottom-right': {
      [theme.breakpoints.down('sm')]: {
        bottom: isMainTrayOpen ? MAIN_TRAY_WIDTH + 53 : 0, // offset for the Quick Info tray
      },
    },

    '@media print': {
      display: 'none',
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

type Props = {
  isMainTrayOpen?: boolean;
};

export const Map = React.memo(_Map);
function _Map({ isMainTrayOpen = false }: Props) {
  const { pnTheme } = usePNThemeContext();
  const { classes } = useStyles({ isMainTrayOpen });

  const { isInitialized } = useMapStorage();

  const { mapRef } = useInitializeMap(map, { pnTheme });

  return (
    <Box className={classes.mapContainer}>
      <PNMap mapRef={mapRef} extraClassName={classes.map} />
      <MapPrintSnapshot />
      <PrintComponents />
      <DrawingCanvas />
      <MapTerms isMainTrayOpen={isMainTrayOpen} />
      <MapButtons />
      <MapAlerts />
      <ProjectLoadingOverlay />

      <DraggableHandle />

      {!isInitialized ? (
        <CircularProgress
          className={classes.loader}
          color="primary"
          size={64}
        />
      ) : (
        <MapEffects />
      )}
    </Box>
  );
}
