import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Alert, Box, Button, IconButton, Stack } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import { isPartialDataReceived } from '@pn/core/domain/workspace';
import { AUTO_VISUALIZE_LIMIT, STREAMING_LIMIT } from '@pn/core/limits';
import { revisualizeWorkspaceItem } from '@pn/core/operations/workspace';
import {
  useMapStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { getMinZoomLevel } from '@pn/core/storage/workspace/minZoomLevels';
import { isEmbedded } from '@pn/core/utils/embedded';
import {
  DrawingMode,
  isExtraDrawingMode,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    textAlign: 'center',
    pointerEvents: 'none',
    '@media print': {
      display: 'none',
    },
  },
  stack: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
  },
  alert: {
    pointerEvents: 'auto',
  },
  alertButton: {
    marginLeft: theme.spacing(2),
  },
}));

export const MapAlerts = () => {
  const { classes } = useStyles();

  const { isInitialized } = useMapStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { isWorkspaceItemPanelOpen } = useWorkspaceItemPanel();
  const { drawingMode } = useDrawing();

  const [zoom, setZoom] = React.useState(Number.POSITIVE_INFINITY);
  React.useEffect(() => {
    if (!isInitialized) return;

    setZoom(map._native.getZoom()); // set initial zoom level
    map._native.on('zoomend', () => setZoom(map._native.getZoom()));
  }, [isInitialized]);

  const isStreamingLimitHit =
    !isNil(workspaceItemSelected) &&
    !isNil(workspaceItemSelected.sourceItem) &&
    workspaceItemSelected.isRendered &&
    workspaceItemSelected.totalCount > STREAMING_LIMIT &&
    workspaceItemSelected.streamedCount === 0;

  const isAutoVisualizeLimitHit =
    !isNil(workspaceItemSelected) &&
    !isNil(workspaceItemSelected.sourceItem) &&
    workspaceItemSelected.isRendered &&
    !isStreamingLimitHit &&
    workspaceItemSelected.totalCount > AUTO_VISUALIZE_LIMIT &&
    workspaceItemSelected.streamedCount === 0;

  const isBelowMinZoomLevel =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.isRendered &&
    zoom < getMinZoomLevel(workspaceItemSelected.id);

  const isPartialData =
    !isNil(workspaceItemSelected) &&
    isPartialDataReceived(workspaceItemSelected);

  const isSpecialDrawingTool = isExtraDrawingMode(drawingMode);

  const handleZoomIn = () => {
    assert(
      workspaceItemSelected,
      'workspaceItemSelected has to be defined to zoom in'
    );
    map.setZoom(getMinZoomLevel(workspaceItemSelected.id), 500);
  };

  if (isEmbedded() || isWorkspaceItemPanelOpen) return null;

  return (
    <Box className={classes.container}>
      <Stack spacing={2} className={classes.stack}>
        {isStreamingLimitHit && (
          <Alert variant="filled" severity="warning" className={classes.alert}>
            Cannot display over {STREAMING_LIMIT.toLocaleString()}{' '}
            {formatDataType(workspaceItemSelected.dataType)}
            <Box component="span" className={classes.alertButton} />
          </Alert>
        )}

        {isAutoVisualizeLimitHit && (
          <Alert
            variant="filled"
            severity="info"
            className={classes.alert}
            icon={false}
          >
            {workspaceItemSelected.totalCount.toLocaleString()}{' '}
            {formatDataType(workspaceItemSelected.dataType)} were matched{' '}
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              className={classes.alertButton}
              onClick={() =>
                workspaceActions().overrideLimit(workspaceItemSelected.id)
              }
            >
              Show
            </Button>
          </Alert>
        )}

        {isPartialData && (
          <Alert
            variant="filled"
            severity="warning"
            className={classes.alert}
            icon={false}
          >
            {workspaceItemSelected.streamedCount.toLocaleString()} out of{' '}
            {workspaceItemSelected.totalCount.toLocaleString()}{' '}
            {formatDataType(workspaceItemSelected.dataType)} shown{' '}
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              className={classes.alertButton}
              onClick={() => revisualizeWorkspaceItem(workspaceItemSelected)}
            >
              Retry
            </Button>
          </Alert>
        )}

        {isBelowMinZoomLevel && (
          <Alert
            variant="filled"
            severity="warning"
            className={classes.alert}
            icon={false}
            sx={{ pt: 0, pb: 0 }}
          >
            Not all {formatDataType(workspaceItemSelected.dataType)} are shown
            at this zoom level{' '}
            <IconButton color="inherit" onClick={handleZoomIn}>
              <ZoomInIcon />
            </IconButton>
          </Alert>
        )}

        {isSpecialDrawingTool && (
          <Alert variant="filled" severity="info" className={classes.alert}>
            {getDrawingHelperText(drawingMode)}
          </Alert>
        )}
      </Stack>
    </Box>
  );
};

function getDrawingHelperText(drawingMode: DrawingMode): string {
  switch (drawingMode) {
    case 'rectangle_select':
      return 'Click and drag to select an area';
    case 'circle_select':
      return 'Click and drag to extend a circle. Hold Alt to expand from the center';
    case 'polygon_select':
      return 'Click to start and to add points. Double-click to finish';
    case 'distance':
      return 'Click to start and to add points. Double-click to finish';
    default:
      return '';
  }
}
