import { colors, type Theme } from '@mui/material';
import { desaturateColor } from '@pn/services/utils/color';

export const sharedThemeTokens: Partial<Theme> = {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: 400,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
};

export const productionColors = {
  generalProduction: desaturateColor(colors.amber[500], 0.2),
  oilProduction: colors.green[600],
  gasProduction: colors.red[400],
  nglAndCondensateProduction: colors.deepPurple[400],
  subtleBarChartColor: colors.blueGrey[300],
};

export const pnBrandColor = '#F50052';

export const typography = {
  fontFamily: ['Roboto', 'sans-serif'].join(', '),
};
