import type {
  INotificationService,
  NotificationType,
} from '@pn/core/services/notifications/ports';
import { generateId } from '@pn/core/utils/id';
import {
  addNotification,
  getNotification,
  gracefullyRemoveNotification,
} from './stackedNotificationStore';

export const stackedNotificationService: INotificationService = {
  notify, // deprecated
  info: (message) => notify(message, 'default'),
  warning: (message) => notify(message, 'warning'),
  error: (message) => notify(message, 'error'),
  render: ({ content, action, options }) => {
    const notificationId = options?.id ?? generateId();

    addNotification({
      id: notificationId,
      type: options?.type ?? 'default',
      content,
      action,
      isPersistent: true,
      showSpinner: options?.showSpinner ?? false,
      showCloseButton: options?.showCloseButton ?? false,
    });

    return notificationId;
  },

  get: (id) => getNotification(id),
  removeNotification: (id) => gracefullyRemoveNotification(id),
};

function notify(message: string, type: NotificationType): void {
  addNotification({
    id: generateId(),
    type,
    content: message,
    isPersistent: false,
    showSpinner: false,
    showCloseButton: false,
  });
}
