export type DrawingMode =
  | 'select'
  | 'freehand'
  | 'line'
  | 'arrow'
  | 'rectangle'
  | 'circle'
  | 'text'
  | 'icon'
  /* Extras */
  | 'rectangle_select'
  | 'circle_select'
  | 'polygon_select'
  | 'distance';

export function isExtraDrawingMode(mode: DrawingMode): boolean {
  return [
    'rectangle_select',
    'circle_select',
    'polygon_select',
    'distance',
  ].includes(mode);
}
