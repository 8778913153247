import { isNumber, isString } from 'lodash-es';
import type { MapboxIMap } from '..';

export const fitToBoundingBox: MapboxIMap['fitToBoundingBox'] = function (
  this: MapboxIMap,
  bbox,
  options = {}
) {
  const { padding = 50, maxZoom = 13 } = options;

  const adjustedPadding = (() => {
    if (isString(padding) && padding.endsWith('%')) {
      const { width, height } = this._native
        .getContainer()
        .getBoundingClientRect();
      return Math.min(width, height) * (parseFloat(padding) / 100);
    }
    if (isNumber(padding)) {
      return padding;
    }
    return 0;
  })();

  this._native.fitBounds([bbox.sw.lon, bbox.sw.lat, bbox.ne.lon, bbox.ne.lat], {
    padding: adjustedPadding,
    maxZoom,
    easing: () => 1,
  });
};
