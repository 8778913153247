import {
  isPolyClosed,
  type CanvasFeature,
  type CircleFeature,
  type PolyFeature,
} from '@pn/core/domain/drawing';
import { pointToGeoPoint, type GeoPoint } from '@pn/core/domain/geography';
import { REFERENCE_PT, REFERENCE_ZOOM } from '@pn/services/drawing/map';
import * as turf from '@turf/turf';
import assert from 'assert';

export function canvasFeatureToGeoJSON(
  feature: CanvasFeature
): GeoJSON.Feature {
  switch (feature.type) {
    case 'circle':
      return circleToGeoJSON(feature);
    case 'poly':
      return polyToGeoJSON(feature);
    default:
      throw new Error(`Unsupported GeoJSON conversion: ${feature.type}`);
  }
}

function circleToGeoJSON(
  feature: CircleFeature
): GeoJSON.Feature<GeoJSON.Polygon> {
  const geoCenter = pointToGeoPoint(
    feature.center,
    REFERENCE_PT,
    REFERENCE_ZOOM
  );
  const geoRightmostPoint = pointToGeoPoint(
    {
      x: feature.center.x + feature.radius,
      y: feature.center.y,
    },
    REFERENCE_PT,
    REFERENCE_ZOOM
  );

  return generateGeoJSONCircle(geoCenter, geoRightmostPoint);
}

function generateGeoJSONCircle(
  center: GeoPoint,
  rightmostPoint: GeoPoint,
  steps = 256
): GeoJSON.Feature<GeoJSON.Polygon> {
  const c = turf.point([center.lon, center.lat]);
  const r = turf.point([rightmostPoint.lon, rightmostPoint.lat]);

  const radius = turf.distance(c, r, { units: 'meters' });
  const circle = turf.circle(c, radius, { steps, units: 'meters' });

  return circle;
}

function polyToGeoJSON(
  feature: PolyFeature
): GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Polygon> {
  const coordinates = feature.coordinates.map((point) => {
    const geoPoint = pointToGeoPoint(point, REFERENCE_PT, REFERENCE_ZOOM);
    return [geoPoint.lon, geoPoint.lat];
  });

  return {
    type: 'Feature',
    properties: {},
    geometry: isPolyClosed(feature)
      ? { type: 'Polygon', coordinates: [coordinates] }
      : { type: 'LineString', coordinates },
  };
}

export function isWithin(
  container: GeoJSON.Geometry,
  candidate: GeoJSON.Geometry
): boolean {
  assert(
    container.type === 'Polygon' || container.type === 'MultiPolygon',
    'Container must be a Polygon or a MultiPolygon'
  );

  switch (candidate.type) {
    case 'Point':
      return isPointInPolygon(container, candidate);
    case 'MultiPoint':
      return isMultiPointInPolygon(container, candidate);
    case 'LineString':
      return isLineIntersectingPolygon(container, candidate);
    case 'MultiLineString':
      return isMultiLineIntersectingPolygon(container, candidate);
    case 'Polygon':
    case 'MultiPolygon':
      return arePolygonsIntersecting(container, candidate);
    default:
      return false;
  }
}

function isPointInPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  point: GeoJSON.Point
): boolean {
  return turf.booleanPointInPolygon(point.coordinates, polygon);
}

function isMultiPointInPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  multiPoint: GeoJSON.MultiPoint
): boolean {
  return multiPoint.coordinates.every((pt) =>
    isPointInPolygon(polygon, { type: 'Point', coordinates: pt })
  );
}

// FIXME won't work if the line runs through the polygon but doesn't have any points inside it
function isLineIntersectingPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  line: GeoJSON.LineString
): boolean {
  return line.coordinates.some((pt) => turf.booleanPointInPolygon(pt, polygon));
}

function isMultiLineIntersectingPolygon(
  polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  multiLine: GeoJSON.MultiLineString
): boolean {
  const lines = turf.flatten(multiLine);
  return lines.features.some((line) =>
    isLineIntersectingPolygon(polygon, line.geometry)
  );
}

function arePolygonsIntersecting(
  polygon1: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  polygon2: GeoJSON.Polygon | GeoJSON.MultiPolygon
): boolean {
  return !turf.booleanDisjoint(polygon1, polygon2);
}
