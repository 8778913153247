import type { DataPoint } from '@pn/services/charts/chartjs/production/chartData';
import { isNil } from 'lodash-es';

/**
 * Lonely data points will be rendered as circles.
 */
export function getPointRadius(data: DataPoint[]): number[] {
  const pointRadius = data.map(({ y }, index) => {
    if (y === null) return 0;

    const prevValue = data[index - 1]?.y;
    const nextValue = data[index + 1]?.y;

    if (isNil(prevValue) && isNil(nextValue)) return 2;

    return 0;
  });

  return pointRadius;
}
