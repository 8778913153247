import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ReducerSync } from '.';
import { intelReportsSlice } from '@pn/intel/src/storage/report/intelReportsSlice.ts';
import { apiIntelReportMapper } from '@pn/services/api/intel-report/apiIntelReportsMapper.ts';
import { isTemporaryId } from '@pn/core/utils/id.ts';

const { actions } = intelReportsSlice;

export const intelReports: ReducerSync<typeof actions> = {
  update: (updatePayload) => {
    const apiIntelReport = apiIntelReportMapper.toTargetIntelReport(
      updatePayload.updatedReport
    );
    return pnApiClient.request({
      method: 'PUT',
      url: `v1/market_intel/intel_reports/${updatePayload.reportId}`,
      payload: apiIntelReport,
    });
  },
  updateEmailSchedule: (updatePayload) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v1/market_intel/intel_reports/${updatePayload.reportId}/email_schedule`,
      payload: {
        email_schedule: updatePayload.emailFrequency,
      },
    });
  },
  updateIsPinned: (updatePayload) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v1/market_intel/intel_reports/${updatePayload.reportId}/is_pinned`,
      payload: {
        is_pinned: updatePayload.isPinned,
      },
    });
  },
  remove: (intelAlertId) => {
    if (isTemporaryId(intelAlertId)) return Promise.resolve();
    return pnApiClient.request({
      method: 'DELETE',
      url: `v1/market_intel/intel_reports/${intelAlertId}`,
    });
  },
};
