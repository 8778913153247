import type { Point } from '@pn/core/domain/point';
import { useMapSelectionProcessor } from '@pn/core/operations/mapInteractions';
import { useAccess } from '@pn/core/permissions/access';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isExtraDrawingMode, useDrawing } from '@pn/services/drawing';
import { toMapboxBbox } from '@pn/services/map/mapbox/mapboxUtils';
import {
  isMapboxDataFeature,
  mapboxMapFeatureMapper,
} from '@pn/services/map/mapbox/mappers/mapboxMapFeatureMapper';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty, maxBy, minBy } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { map, notify } from 'src/application/externalDependencies';

let start: mapboxgl.Point; // starting xy coordinates when `mousedown` occured
let current: mapboxgl.Point; // current xy coordinates when `mousemove` or `mouseup` occurs
let box: HTMLElement | undefined; // draw box element

export function useMapBoxSelection() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingMode } = useDrawing();
  const { processMixedMapSelection } = useMapSelectionProcessor();

  const access = useAccess();

  const skip = isDrawingPanelOpen || isExtraDrawingMode(drawingMode);

  const handleMapMouseDown = React.useCallback(
    (e: MouseEvent) => {
      if (isEmbedded() || !e.shiftKey || skip) return;

      map._native.dragPan.disable();
      const canvas = map._native.getCanvasContainer();

      start = mousePosition(e); // capture the first xy coordinates

      // Returns the xy coordinates of the mouse position
      function mousePosition(e: MouseEvent) {
        const rect = canvas.getBoundingClientRect();

        const x = e.clientX - rect.left - canvas.clientLeft;
        const y = e.clientY - rect.top - canvas.clientTop;

        return new mapboxgl.Point(x, y);
      }

      function onMouseMove(e: MouseEvent) {
        // Capture the ongoing xy coordinates
        current = mousePosition(e);

        // Append the box element if it doesnt exist
        if (!box) {
          box = document.createElement('div');
          box.classList.add('mapbox-boxdraw');
          canvas.appendChild(box);
        }

        const minX = Math.min(start.x, current.x);
        const maxX = Math.max(start.x, current.x);
        const minY = Math.min(start.y, current.y);
        const maxY = Math.max(start.y, current.y);

        // Adjust width and xy position of the box element ongoing
        box.style.transform = `translate(${minX}px, ${minY}px)`;
        box.style.width = maxX - minX + 'px';
        box.style.height = maxY - minY + 'px';
      }

      function onMouseUp(e: MouseEvent) {
        finish([start, mousePosition(e)]);
      }

      function finish(bbox: [mapboxgl.Point, mapboxgl.Point]) {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        map._native.dragPan.enable();

        if (box) {
          box.parentNode!.removeChild(box);
          box = undefined;
        }

        if (access('multiSelection').notify().denied()) return;

        const bboxResized = shrinkBbox(bbox);

        const allFeatures = map._native.queryRenderedFeatures(
          toMapboxBbox(bboxResized)
        );

        const dataFeatures = allFeatures.filter(isMapboxDataFeature);

        if (isEmpty(dataFeatures)) {
          return notify('No selectable features available');
        }

        processMixedMapSelection({
          features: dataFeatures.map(mapboxMapFeatureMapper.toDomainMapFeature),
          append: e.ctrlKey,
        });
      }

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [skip, access, processMixedMapSelection]
  );

  React.useEffect(() => {
    const canvas = map._native.getCanvasContainer();

    /**
     * Set `true` to dispatch the event before other functions call it.
     * This is necessary for disabling the default map dragging behaviour.
     */
    canvas.addEventListener('mousedown', handleMapMouseDown, true);

    return () => {
      canvas.removeEventListener('mousedown', handleMapMouseDown, true);
    };
  }, [handleMapMouseDown]);
}

function shrinkBbox(bbox: [Point, Point]): [Point, Point] {
  const SHRINK_FACTOR = 5; // in px

  const minX = minBy(bbox, (pt) => pt.x)!.x;
  const minY = minBy(bbox, (pt) => pt.y)!.y;
  const maxX = maxBy(bbox, (pt) => pt.x)!.x;
  const maxY = maxBy(bbox, (pt) => pt.y)!.y;

  const pt1 = { x: minX + SHRINK_FACTOR, y: minY + SHRINK_FACTOR };
  const pt2 = { x: maxX - SHRINK_FACTOR, y: maxY - SHRINK_FACTOR };

  return [pt1, pt2];
}
