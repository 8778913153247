import { isNil } from 'lodash-es';
import React from 'react';

export function usePrevious<T>(
  value: T,
  initialValue: NonNullable<T>
): NonNullable<T>;
export function usePrevious<T>(value: T): T | undefined;
export function usePrevious<T>(value: T, initialValue?: T): T | undefined {
  const ref = React.useRef<T | undefined>(initialValue);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useWebStorageState<T>(
  key: string,
  defaultValue: T,
  storage: Storage = localStorage
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = React.useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(storage.getItem(key) ?? '');
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  React.useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [value, key, storage]);

  return [value, setValue];
}
