import { alpha, ClassNameMap, colors } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { MultiColumnRow, MultiColumnTable } from '@pn/core/domain/data-info';
import {
  DataMultiSelectionReason,
  DataSelectionReason,
} from '@pn/core/domain/query';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import { useAccess } from '@pn/core/permissions/access';
import {
  useAppStorage,
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import assert from 'assert';
import { isString } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { TableComponent } from './MultiColumnTable';
import { createWorkspaceItem } from '@pn/core/domain/workspace';
import { formatDataType } from '@pn/core/domain/data';
import { useRouter } from '@pn/core/operations/router';
import { getMainPanelUri } from 'src/ui/main-panel/utils';
import { LayerType } from '@pn/core/domain/layer';

const useStyles = makeStyles()(() => ({
  divider: {
    marginBottom: 2,
  },
}));

type Props = {
  classes: ClassNameMap;
  table: MultiColumnTable;
};

export function ConnectedAssetsTableComponent({ classes, table }: Props) {
  const { location, pushUrl } = useRouter();
  const { classes: extraClasses } = useStyles();

  const access = useAccess();
  const { pageSize } = useAppStorage();
  const { user } = useCurrentUserStorage();

  const handleViewOnMap = (row: MultiColumnRow) => {
    assert(isString(row.link_type), 'link_type is not a string');
    assert(isString(row.link_id), 'link_id is not a string');

    if (access('allData', row.link_type).notify().denied()) return;

    const sourceItem = findOrThrow(
      pnWorkspaceItems,
      ({ id }) => id === row.link_type
    );

    assert(user, 'User must be defined when processing linked IDs');

    const newItem = createWorkspaceItem(
      {
        source: 'item',
        sourceItem,
        id: `connected-${formatDataType(row.link_type, { form: 'singular' })}-${row.link_id}`,
        name: `Connected ${formatDataType(row.link_type, { form: 'singular' })}`,
        extraStyle: {
          color:
            sourceItem.map.layers[0]?.type === LayerType.Polygon
              ? alpha(colors.teal[500], 0.5)
              : colors.teal[500],
        },
        queryOptions: {
          requestedIds: [row.link_id],
          multiSelectionReason: DataMultiSelectionReason.LinkedIds,
        },
      },
      user
    );

    workspaceActions().add(newItem);
    workspaceActions().addToWorkspace(newItem.id);
    workspaceActions().select(newItem.id);

    selectDataItem({
      id: row.link_id,
      reason: DataSelectionReason.ConnectedAssets,
      workspaceItem: newItem,
      pageSize,
    });

    /* Reset URL */
    pushUrl({
      pathname: getMainPanelUri({
        dataType: row.link_type,
        dataItemId: row.link_id,
      }),
      search: location.search,
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.tableTitle}>{table.title}</Typography>
      </Box>
      <Divider className={extraClasses.divider} />
      <TableComponent
        classes={classes}
        table={table}
        onButtonClick={handleViewOnMap}
      />
    </Box>
  );
}
