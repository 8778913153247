import WarningIcon from '@mui/icons-material/Warning';
import { Box, Tooltip, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { getZoom, useDrawing } from '@pn/services/drawing';
import { NumericInputWithToggles } from '@pn/ui/inputs/NumericInputWithToggles';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const StrokeWidth = ({ featureLive }: Props) => {
  const {
    drawingMode,
    strokeWidth,
    setStrokeWidth,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value =
    featureLive && 'strokeWidth' in featureLive
      ? featureLive.strokeWidth * getZoom()
      : ['freehand', 'line', 'arrow', 'rectangle', 'circle'].includes(
            drawingMode
          )
        ? strokeWidth
        : undefined;

  const handleChange = (width: number) => {
    setStrokeWidth(width);
    drawingState.strokeWidth = width;

    if (featureLive && 'strokeWidth' in featureLive) {
      const zoomedWidth = width / getZoom();

      set(
        drawingState.featuresSelected[featureLive.id],
        'strokeWidth',
        zoomedWidth
      );
      set(drawingState.features[featureLive.id], 'strokeWidth', zoomedWidth);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Stroke width
      </Typography>
      <Box display="flex" alignItems="center" gap="29px">
        <NumericInputWithToggles
          toggles={[{ value: 2 }, { value: 5 }, { value: 10 }, { value: 25 }]}
          value={value}
          units="px"
          onChange={handleChange}
        />
        <Tooltip title="Relative to the current zoom level">
          <WarningIcon color="primary" />
        </Tooltip>
      </Box>
    </Box>
  );
};
