import { dependencies } from '@pn/core/dependencies';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import mapboxgl from 'mapbox-gl';

// TODO add Mapbox attribution
// TODO move to the Mapbox service

export async function printMap(
  printDimensions: [number, number]
): Promise<string> {
  const { map } = dependencies;

  const dpi = 300;

  // Override devicePixelRatio to simulate higher resolution
  const originalDevicePixelRatio = window.devicePixelRatio;
  Object.defineProperty(window, 'devicePixelRatio', {
    get: () => dpi / 96,
  });

  // Create a temporary container for the high-resolution map
  const container = document.createElement('div');
  container.style.width = printDimensions[0] + 'px';
  container.style.height = printDimensions[1] + 'px';
  container.style.position = 'absolute';
  container.style.top = -1 * Math.max(...printDimensions) + 'px';
  document.body.appendChild(container);

  try {
    const currentViewportWidth = map._native.getContainer().offsetWidth;
    const currentViewportHeight = map._native.getContainer().offsetHeight;

    const widthFactor = printDimensions[0] / currentViewportWidth;
    const heightFactor = printDimensions[1] / currentViewportHeight;

    // Use the smaller factor to fit everything and maintain aspect ratio
    const scaleFactor = Math.min(widthFactor, heightFactor);

    // Create a new map instance for rendering
    const renderMap = new mapboxgl.Map({
      container: container,
      center: map._native.getCenter(),
      style: map._native.getStyle(),
      bearing: map._native.getBearing(),
      pitch: map._native.getPitch(),
      interactive: false,
      attributionControl: false,
      preserveDrawingBuffer: true, // required to capture the canvas
      zoom: map._native.getZoom() + Math.log2(scaleFactor),
    });

    // Wait for the map to finish rendering
    await new Promise<void>((resolve) => renderMap.once('idle', resolve));

    // Render the map container as a canvas using html2canvas
    const canvas = await html2canvas(container, {
      useCORS: true,
      scale: 1, // 1:1 scale, as we are already controlling resolution via container size
    });

    // Create a new PDF document
    const pdf = new jsPDF({
      orientation:
        printDimensions[0] > printDimensions[1] ? 'landscape' : 'portrait',
      unit: 'px',
      format: printDimensions,
    });

    // Add the canvas as an image to the PDF
    pdf.addImage(
      canvas.toDataURL('image/jpeg', 1.0),
      'JPEG',
      0,
      0,
      ...printDimensions
    );

    const fileName = `PN Map ${printDimensions[0]}x${printDimensions[1]} (${format(new Date(), 'h_mm_ss aa')}).pdf`;
    pdf.save(fileName);

    // Clean up
    renderMap.remove();
    document.body.removeChild(container);
    Object.defineProperty(window, 'devicePixelRatio', {
      get: () => originalDevicePixelRatio,
    });

    return `PDF generated successfully: ${fileName}`;
  } catch (error) {
    // Clean up on error
    document.body.removeChild(container);
    Object.defineProperty(window, 'devicePixelRatio', {
      get: () => originalDevicePixelRatio,
    });

    return `Error generating PDF: ${(error as Error).message}`;
  }
}
