import type { DataItem } from '@pn/core/domain/data';
import {
  geoShapeToGeometry,
  getCenterPoint,
  getDistance,
  isGeoPoint,
  type GeoBoundingBox,
  type GeoPoint,
} from '@pn/core/domain/geography';
import type { Layer } from '@pn/core/domain/layer';
import type { MapFeature } from '@pn/core/domain/map';
import type { Point } from '@pn/core/domain/point';
import { isDevelopment } from '@pn/core/utils/env';
import { murmurhash3 } from '@pn/services/utils/hash';
import { isNil, isString } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import type { MapboxStyle } from './mappers/mapboxLayerStyleMapper';

export function toMapboxPoint(point: Point): mapboxgl.Point {
  return new mapboxgl.Point(point.x, point.y);
}

export function toMapboxBbox(
  bbox: [Point, Point]
): [mapboxgl.Point, mapboxgl.Point] {
  return [toMapboxPoint(bbox[0]), toMapboxPoint(bbox[1])];
}

export function geoBoundingBoxToMapboxBbox(
  mapboxMap: mapboxgl.Map,
  bbox: GeoBoundingBox
): [mapboxgl.Point, mapboxgl.Point] {
  const sw = mapboxMap.project([bbox.sw.lon, bbox.sw.lat]);
  const ne = mapboxMap.project([bbox.ne.lon, bbox.ne.lat]);

  return [sw, ne];
}

export function toMapboxLngLatLike(geoPoint: GeoPoint): mapboxgl.LngLatLike {
  return geoPoint;
}

export function applyMapboxStyle(
  mapboxMap: mapboxgl.Map,
  layerId: Layer['id'],
  mapboxStyle: MapboxStyle
) {
  if (!isNil(mapboxStyle.layout)) {
    for (const [property, value] of Object.entries(mapboxStyle.layout)) {
      // console.log('setLayoutProperty', layerId, property, value);
      mapboxMap.setLayoutProperty(layerId, property, value, {
        validate: isDevelopment(),
      });
    }
  }

  if (!isNil(mapboxStyle.paint)) {
    for (const [property, value] of Object.entries(mapboxStyle.paint)) {
      // console.log('setPaintProperty', layerId, property, value);
      mapboxMap.setPaintProperty(layerId, property, value, {
        validate: isDevelopment(),
      });
    }
  }
}

/**
 * Each source data can be transformed into a points collection. This is useful
 * for rendering polygon features at lower zoom levels.
 */
export function generateGeoJSONFeatureCollection(
  data: DataItem[],
  options?: {
    points?: boolean;
  }
): GeoJSON.FeatureCollection {
  const { points = false } = options ?? {};

  const geojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  for (const item of data) {
    if (!isNil(item.geoShape)) {
      let featureGeometry: GeoJSON.Geometry;

      if (points) {
        const centerPoint = getCenterPoint(item.geoShape);
        featureGeometry = {
          type: 'Point',
          coordinates: [centerPoint.lon, centerPoint.lat],
        };
      } else {
        featureGeometry = geoShapeToGeometry(item.geoShape);
      }

      const featureId = isString(item._id) ? murmurhash3(item._id) : item._id;
      geojson.features.push({
        type: 'Feature',
        id: featureId,
        geometry: featureGeometry,
        properties: item,
      });
    } else {
      console.error('Missing geoShape from', item);
    }
  }

  return geojson;
}

/**
 * First, this function finds a single closest feature which is then used as an
 * anchor point for further calculations. Then, all other features that are less
 * than `radius` meters away from the anchor are returned.
 */
export function getClosestFeatures(
  centerPoint: GeoPoint,
  features: MapFeature[],
  radius = 0
): MapFeature[] {
  const anchorFeature = getClosestFeature(centerPoint, features);

  const closestFeatures: MapFeature[] = [];
  features.forEach((feature) => {
    if (
      isGeoPoint(feature.geoShape.shape) &&
      isGeoPoint(anchorFeature.geoShape.shape)
    ) {
      const distance = getDistance([
        anchorFeature.geoShape.shape,
        feature.geoShape.shape,
      ]);

      if (distance <= radius) {
        closestFeatures.push(feature);
      }
    } else {
      closestFeatures.push(feature);
    }
  });

  return closestFeatures;
}

function getClosestFeature(
  centerPoint: GeoPoint,
  features: MapFeature[]
): MapFeature {
  let closestFeature: MapFeature;
  let closestDistance = Number.MAX_VALUE;

  features.forEach((feature) => {
    if (isGeoPoint(feature.geoShape.shape)) {
      const distance = getDistance([centerPoint, feature.geoShape.shape]);
      if (distance < closestDistance) {
        closestFeature = feature;
        closestDistance = distance;
      }
    } else {
      closestFeature = feature;
    }
  });

  return closestFeature!;
}
