import type { CanvasFeature } from '@pn/core/domain/drawing';
import { useDrawing } from '@pn/services/drawing';
import React from 'react';
import { FillColor } from './FillColor';
import { FontSize } from './FontSize';
import { IconColor } from './IconColor';
import { IconPicker } from './IconPicker';
import { IconSize } from './IconSize';
import { Measurements } from './Measurements';
import { Opacity } from './Opacity';
import { Order } from './Order';
import { StrokeColor } from './StrokeColor';
import { StrokeWidth } from './StrokeWidth';
import { TextColor } from './TextColor';

export const DrawingPanelControls = () => {
  const { lastRedraw, drawingState } = useDrawing();

  const [featureLive, setFeatureLive] = React.useState<
    CanvasFeature | undefined
  >();

  React.useLayoutEffect(() => {
    const featuresSelectedArr = Object.values(drawingState.featuresSelected);
    if (featuresSelectedArr.length === 1) {
      setFeatureLive(featuresSelectedArr[0]);
    } else {
      setFeatureLive(undefined);
    }
  }, [drawingState, lastRedraw]);

  return (
    <>
      <StrokeColor featureLive={featureLive} />
      <FillColor featureLive={featureLive} />
      <TextColor featureLive={featureLive} />
      <IconColor featureLive={featureLive} />

      <StrokeWidth featureLive={featureLive} />
      <FontSize featureLive={featureLive} />
      <IconSize featureLive={featureLive} />
      <Opacity featureLive={featureLive} />

      <IconPicker featureLive={featureLive} />

      <Measurements featureLive={featureLive} />
      <Order featureLive={featureLive} />
    </>
  );
};
