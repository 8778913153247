type ExportOption = {
  dataType: string;
  name: string;
  type: string;
  description: string;
  format: string;
};

export const multiExportOptions: ExportOption[] = [
  /* Wells */

  {
    dataType: 'wells',
    name: 'Wells Info',
    type: 'export_well_data_canada',
    description: 'Well Header summary',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Wells Reports',
    type: 'export_well_files_canada',
    description: 'Separate CSV with Well Details for each well',
    format: 'zip',
  },
  {
    dataType: 'wells',
    name: 'Basic Production',
    type: 'export_production_data_canada',
    description:
      'Daily and monthly Oil, Gas, Water, Water Injection, and Steam Injection volumes',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'All Production',
    type: 'export_production_v2_data_canada',
    description: 'Monthly volumes for all types of production',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Production Summaries',
    type: 'export_production_summaries_canada',
    description: 'Cumulative production volumes',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Val Nav Production',
    type: 'export_valnav_production_data_canada',
    description: 'Val Nav-compatible production export',
    format: 'mer',
  },
  {
    dataType: 'wells',
    name: 'Mosaic Data',
    type: 'export_mosaic_data_canada',
    description: 'Mosaic-compatible production export',
    format: 'xlsx',
  },
  {
    dataType: 'wells',
    name: 'Gas Analysis',
    type: 'export_gas_analysis_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Casings',
    type: 'export_casings_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Cement',
    type: 'export_cement_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Completions',
    type: 'export_completions_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Tops',
    type: 'export_formations_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Directionals',
    type: 'export_directionals_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Gradient Pressure Data',
    type: 'export_gradient_pressure_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Transient Pressure Data',
    type: 'export_transient_pressure_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Drilling Details',
    type: 'export_drilling_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Drilling Fluid Report',
    type: 'export_drilling_fluid_report_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'LAS Files',
    type: 'export_las_files_canada',
    description: '',
    format: 'zip',
  },
  {
    dataType: 'wells',
    name: 'Core Analysis',
    type: 'export_digital_core_data_canada',
    description: '',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Surface Casing Vent Flow',
    type: 'export_scvf_data_canada',
    description: 'Surface Casing Vent Flow (SCVF)',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Wells Shapefile',
    type: 'export_wells_shapefile_canada',
    description: '',
    format: 'zip',
  },
  {
    dataType: 'wells',
    name: 'Wells Paths Shapefile',
    type: 'export_well_paths_shapefile_canada',
    description: '',
    format: 'zip',
  },

  /* Wells USA */

  {
    dataType: 'wells_usa',
    name: 'Wells Info',
    type: 'export_well_data_usa',
    description: 'Well Header summary',
    format: 'csv',
  },
  {
    dataType: 'wells_usa',
    name: 'Basic Production',
    type: 'export_production_data_usa',
    description: 'Daily and monthly Oil, Gas, Water, and Injection volumes',
    format: 'csv',
  },

  /* Pipelines */

  {
    dataType: 'pipelines',
    name: 'Pipelines Info',
    type: 'export_pipeline_data_canada',
    description: 'Pipeline Header summary',
    format: 'csv',
  },
  {
    dataType: 'pipelines',
    name: 'Pipelines GeoJSON',
    type: 'export_pipelines_geojson_canada',
    description: '',
    format: 'geojson',
  },
  {
    dataType: 'pipelines',
    name: 'Pipelines Shapefile',
    type: 'export_pipelines_shapefile_canada',
    description: '',
    format: 'zip',
  },

  /* Facilities */

  {
    dataType: 'facilities',
    name: 'Facilities Info',
    type: 'export_facility_data_canada',
    description: 'Facility Header summary',
    format: 'csv',
  },
  {
    dataType: 'facilities',
    name: 'Facilities Shapefile',
    type: 'export_facilities_shapefile_canada',
    description: '',
    format: 'zip',
  },

  /* Mineral Rights */

  {
    dataType: 'mineral_rights',
    name: 'Mineral Rights Info',
    type: 'export_land_data_canada',
    description: 'Mineral Rights summary',
    format: 'csv',
  },
  {
    dataType: 'mineral_rights',
    name: 'Mineral Rights by Land ID',
    type: 'export_land_by_land_id_data_canada',
    description: 'Mineral Rights summary by Land ID',
    format: 'csv',
  },
  {
    dataType: 'mineral_rights',
    name: 'Crown Land Shapefile',
    type: 'export_mineral_rights_shapefile_canada',
    description: '',
    format: 'zip',
  },
];
