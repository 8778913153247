import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import LockIcon from '@mui/icons-material/Lock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import SourceIcon from '@mui/icons-material/Source';
import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { LibraryFolder } from '@pn/core/domain/project';
import { getFullName } from '@pn/core/domain/user';
import { WorkspaceItem } from '@pn/core/domain/workspace';
import { useCurrentUserStorage } from '@pn/core/storage';
import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash-es';
import { ConvertToStackButton } from 'src/ui/workspace/components/ConvertToStackButton';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { makeStyles } from 'tss-react/mui';

const syncableDataTypes = ['wells', 'pipelines', 'facilities'];

const useStyles = makeStyles()((theme) => ({
  listItem: {
    height: 52,
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  sourceItemName: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  openOriginUrlButton: {
    position: 'relative',
    top: -1,
    marginLeft: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const ItemInformation = ({ item }: Props) => {
  const { classes } = useStyles();

  const { user, isStackUser } = useCurrentUserStorage();

  const { openLibrary, setLibraryRouter } = useLibrary();

  const isCreatedByCurrentUser =
    !isNil(item.createdBy) && !isNil(user) && item.createdBy.id === user.id;
  const isSyncable =
    isStackUser &&
    isCreatedByCurrentUser &&
    !item.isGlobal &&
    item.origin !== 'stackdx' &&
    (syncableDataTypes.includes(item.dataType) ||
      item.sourceItem?.origin === 'stackdx');
  const isStackList = item.origin === 'stackdx' && !isNil(item.sourceItem);

  const handleOpenTab = (folder: LibraryFolder) => {
    setLibraryRouter({ section: 'items', folder });
    openLibrary();
  };

  if (item.isTemporary) {
    return (
      <List dense disablePadding={item.itemType === 'drawing'}>
        <ListItem disableGutters disablePadding className={classes.listItem}>
          <ListItemIcon>
            <HistoryToggleOffIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Work in progress"
            secondary={
              <span>
                Will be saved to the{' '}
                <Link
                  className={classes.link}
                  onClick={() => handleOpenTab('Personal')}
                >
                  Personal
                </Link>{' '}
                tab
              </span>
            }
          />
        </ListItem>
      </List>
    );
  }

  return (
    <List dense disablePadding={item.itemType === 'drawing'}>
      <ListItem disableGutters disablePadding className={classes.listItem}>
        <ListItemIcon>
          {item.isGlobal ? (
            <PublicIcon className={classes.icon} />
          ) : isCreatedByCurrentUser ? (
            <PersonIcon className={classes.icon} />
          ) : (
            <LockIcon className={classes.icon} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={getInfoText(item, isCreatedByCurrentUser).primary}
          secondary={getInfoText(item, isCreatedByCurrentUser).secondary}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>

      {isStackList && item.metadata?.dataOriginDescription && (
        <ListItem disableGutters disablePadding className={classes.listItem}>
          <ListItemIcon>
            <SourceIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                Origin:{' '}
                <span className={classes.sourceItemName}>
                  {item.metadata.dataOriginDescription}
                </span>
                {!isNil(item.originUrl) && (
                  <IconButton
                    size="small"
                    color="info"
                    className={classes.openOriginUrlButton}
                    component="a"
                    href={item.originUrl}
                    target="_blank"
                  >
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                )}
              </span>
            }
            primaryTypographyProps={{ noWrap: true }}
          />
        </ListItem>
      )}

      {isSyncable && (
        <ConvertToStackButton disabled={!isCreatedByCurrentUser} item={item} />
      )}
    </List>
  );
};

function getInfoText(
  item: WorkspaceItem,
  isCreatedByCurrentUser: boolean
): {
  primary: string;
  secondary: string;
} {
  let primary = '';
  let secondary = '';

  if (item.itemType === 'annotation') {
    primary = `Last modified on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'Deprecated annotations can no longer be updated';
  } else if (item.origin === 'stackdx') {
    primary = `Last modified on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This layer can only be edited through StackDX';
  } else if (isCreatedByCurrentUser) {
    primary = `Last modified on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This layer can only be edited by you';
  } else if (!isNil(item.createdBy)) {
    primary = `Last modified by ${getFullName(item.createdBy)} on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This layer can only be edited by its author';
  } else {
    primary = 'Created by Petro Ninja';
    secondary = 'This layer cannot be modified';
  }

  return { primary, secondary };
}
