import { dependencies } from '@pn/core/dependencies';
import { GenericError, isCustomError, isGenericError } from '@pn/core/errors';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';

export type HandleError = {
  error: unknown;
  userFriendlyMessage?: string;
  onError?: (...args: any[]) => void;
};

export const handleError = ({
  error,
  userFriendlyMessage,
  onError = () => {},
}: HandleError) => {
  if (!isGenericError(error) && !isCustomError(error)) {
    /**
     * This should never happen but if it does, nothing will follow.
     */
    console.log('>>> Not an error! Do nothing', error);
    return;
  }

  let handler;
  if (isCustomError(error)) {
    /**
     * These are the errors that are explicitly coded into the application.
     * E.g. ConfigurationError, ApiError, ApplicationError.
     */
    handler = error;
  } else {
    /**
     * These are the unexpected run-time errors that usually arise from bad code.
     * E.g. trying to access a property on an object that can be undefined.
     */
    handler = GenericError;
  }

  handler.handle({
    error,
    errorLogger: dependencies.errorLogger,
    notificationService: dependencies.notificationService,
    userFriendlyMessage,
    userId: getCurrentUserId(),
    onError,
  });
};
