import { useWorkspaceStorage, workspaceActions } from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { isExtraDrawingMode, useDrawing } from '@pn/services/drawing';
import { useExternalPanel } from '@pn/ui/context-components/ExternalPanel';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import { map } from 'src/application/externalDependencies';

type MapClickEvent = mapboxgl.MapMouseEvent & mapboxgl.EventData;

export function useVectorMapClicks() {
  const { workspaceItems } = useWorkspaceStorage();

  const { handleOpen: handleOpenExternalPanel } = useExternalPanel();
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingMode } = useDrawing();

  const [debounced] = useDebouncedValue(isExtraDrawingMode(drawingMode), 200);
  const skip =
    isDrawingPanelOpen || isExtraDrawingMode(drawingMode) || debounced;

  const vectorItems = React.useMemo(() => {
    return workspaceItems.filter(
      (item) =>
        item.dataSource.type === 'none' &&
        item.map.layers[0].source.type === 'vector'
    );
  }, [workspaceItems]);

  const handleMapFeatureClick = React.useCallback(
    (e: MapClickEvent) => {
      const item = findOrThrow(vectorItems, (item) =>
        item.map.layers.map(({ id }) => id).includes(e.features[0].layer.id)
      );

      workspaceActions().select(item.id);
      handleOpenExternalPanel(
        e.features.map((f: mapboxgl.MapboxGeoJSONFeature) => ({
          _item: {
            id: item.id,
            name: item.name,
          },
          ...f.properties,
        }))
      );
    },
    [handleOpenExternalPanel, vectorItems]
  );

  React.useEffect(() => {
    if (skip) return;

    const vectorLayerIds = vectorItems.flatMap((item) =>
      item.map.layers.map(({ id }) => id)
    );

    map._native.on('click', vectorLayerIds, handleMapFeatureClick);

    return () => {
      map._native.off('click', vectorLayerIds, handleMapFeatureClick);
    };
  }, [skip, vectorItems, handleMapFeatureClick]);
}
