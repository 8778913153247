import type { Point } from '@pn/core/domain/point';
import {
  isGeoJSONPosition,
  isValidLonLat,
  type GeoJSONCoordinates,
} from '@pn/core/utils/geospatial';
import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { area, length, lineString, polygon } from '@turf/turf';
import { isArray, isObject } from 'lodash-es';

export type GeoPoint = {
  lat: number;
  lon: number;
};
export function isGeoPoint(geoPoint: unknown): geoPoint is GeoPoint {
  return (
    isObject(geoPoint) && hasKey(geoPoint, 'lat') && hasKey(geoPoint, 'lon')
  );
}

export function toGeoPoint(lat: number, lon: number): GeoPoint {
  return { lat, lon };
}

export enum GeoShapeType {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  Line = 'Line',
  MultiLine = 'MultiLine',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
}
function isGeoShapeType(arg: unknown): arg is GeoShapeType {
  return [
    GeoShapeType.Point,
    GeoShapeType.MultiPoint,
    GeoShapeType.Line,
    GeoShapeType.MultiLine,
    GeoShapeType.Polygon,
    GeoShapeType.MultiPolygon,
  ].includes(arg as GeoShapeType);
}

export type GeoPointsCollection = GeoPoint | GeoPointsCollection[];
export type GeoShape = {
  type: GeoShapeType;
  shape: GeoPointsCollection;
};
export function isGeoShape(arg: unknown): arg is GeoShape {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'type', isGeoShapeType) &&
    hasKeyWithType(arg, 'shape', isArrayOrObject)
  );
}

const isArrayOrObject = (arg: unknown): arg is object | unknown[] =>
  isArray(arg) || isObject(arg);

function toGeometryType(
  geoShapeType: GeoShapeType
): GeoJSON.GeoJsonGeometryTypes {
  switch (geoShapeType) {
    case GeoShapeType.Point:
      return 'Point';
    case GeoShapeType.MultiPoint:
      return 'MultiPoint';
    case GeoShapeType.Line:
      return 'LineString';
    case GeoShapeType.MultiLine:
      return 'MultiLineString';
    case GeoShapeType.Polygon:
      return 'Polygon';
    case GeoShapeType.MultiPolygon:
      return 'MultiPolygon';
    default:
      throw new Error(`Invalid GeoShape type: ${geoShapeType}`);
  }
}

function toGeoShapeType(
  geometryType: GeoJSON.GeoJsonGeometryTypes
): GeoShapeType {
  switch (geometryType) {
    case 'Point':
      return GeoShapeType.Point;
    case 'MultiPoint':
      return GeoShapeType.MultiPoint;
    case 'LineString':
      return GeoShapeType.Line;
    case 'MultiLineString':
      return GeoShapeType.MultiLine;
    case 'Polygon':
      return GeoShapeType.Polygon;
    case 'MultiPolygon':
      return GeoShapeType.MultiPolygon;
    default:
      throw new Error(`Unsupported geometry type: ${geometryType}`);
  }
}

export function geometryToGeoShape(geometry: GeoJSON.Geometry): GeoShape {
  const convertCoordinatesRecursive = (
    coordinates: GeoJSONCoordinates
  ): GeoPointsCollection => {
    if (isGeoJSONPosition(coordinates)) {
      return toGeoPoint(coordinates[1], coordinates[0]);
    } else {
      return coordinates.map((el) =>
        convertCoordinatesRecursive(el)
      ) as GeoPointsCollection;
    }
  };

  if (geometry.type === 'GeometryCollection') {
    throw new Error('GeometryCollection cannot be converted to GeoShape');
  }

  return {
    type: toGeoShapeType(geometry.type),
    shape: convertCoordinatesRecursive(geometry.coordinates),
  };
}

export function geoShapeToGeometry(geoShape: GeoShape): GeoJSON.Geometry {
  const convertGeoPointsRecursive = (
    param: GeoPointsCollection
  ): GeoJSONCoordinates => {
    if (isGeoPoint(param)) {
      if (!isValidLonLat([param.lon, param.lat])) {
        throw new Error(`Invalid GeoPoint: [${param.lat}, ${param.lon}]`);
      }
      return [param.lon, param.lat];
    } else if (isArray(param)) {
      return param.map((el) =>
        convertGeoPointsRecursive(el)
      ) as GeoJSONCoordinates;
    } else {
      console.error(param);
      throw new Error('Invalid GeoPointsCollection');
    }
  };

  return {
    type: toGeometryType(geoShape.type),
    coordinates: convertGeoPointsRecursive(geoShape.shape),
  } as GeoJSON.Geometry;
}

export function getCenterPoint(geoShape: GeoShape): GeoPoint {
  return getMiddlePoint(flattenShape(geoShape.shape));
}

function flattenShape(shape: GeoPointsCollection): GeoPoint[] {
  if (isGeoPoint(shape)) {
    return [shape];
  } else {
    return shape.flatMap((el) => flattenShape(el));
  }
}

function getMiddlePoint(geoPoints: GeoPoint[]): GeoPoint {
  const [minLon, minLat] = geoPoints.reduce(
    ([minLon, minLat], geoPoint) => {
      return [Math.min(minLon, geoPoint.lon), Math.min(minLat, geoPoint.lat)];
    },
    [180, 90]
  );

  const [maxLon, maxLat] = geoPoints.reduce(
    ([maxLon, maxLat], geoPoint) => {
      return [Math.max(maxLon, geoPoint.lon), Math.max(maxLat, geoPoint.lat)];
    },
    [-180, -90]
  );

  return toGeoPoint((minLat + maxLat) / 2, (minLon + maxLon) / 2);
}

function isArrayOfGeoPoints(arg: unknown): arg is GeoPoint[] {
  return isArray(arg) && arg.every(isGeoPoint);
}

function isArrayOfGeoShapes(arg: unknown): arg is GeoShape[] {
  return isArray(arg) && arg.every(isGeoShape);
}

export function getPointsApproximation(arg: GeoPointsCollection): GeoPoint[] {
  if (isGeoPoint(arg)) {
    return [arg];
  } else if (isArrayOfGeoPoints(arg)) {
    if (arg.length === 1) {
      return arg;
    } else {
      return [arg[0], arg[arg.length - 1]];
    }
  } else if (isArray(arg)) {
    return arg.reduce<GeoPoint[]>((geoPoints, geoPointsCollection) => {
      const newPoints = getPointsApproximation(geoPointsCollection);
      Array.prototype.push.apply(geoPoints, newPoints);
      return geoPoints;
    }, []);
  } else {
    throw new Error('Invalid argument');
  }
}

export type GeoBoundingBox = {
  sw: GeoPoint; // bottomLeft
  ne: GeoPoint; // topRight
};

export function getBoundingBox(arg: GeoPoint[] | GeoShape[]): GeoBoundingBox {
  let minLon = 180,
    minLat = 90,
    maxLon = -180,
    maxLat = -90;

  if (isArrayOfGeoPoints(arg)) {
    for (const point of arg) {
      minLon = Math.min(minLon, point.lon);
      minLat = Math.min(minLat, point.lat);
      maxLon = Math.max(maxLon, point.lon);
      maxLat = Math.max(maxLat, point.lat);
    }
  } else if (isArrayOfGeoShapes(arg)) {
    for (const shape of arg) {
      for (const point of flattenShape(shape.shape)) {
        minLon = Math.min(minLon, point.lon);
        minLat = Math.min(minLat, point.lat);
        maxLon = Math.max(maxLon, point.lon);
        maxLat = Math.max(maxLat, point.lat);
      }
    }
  } else {
    throw new Error('Invalid argument');
  }

  return {
    sw: { lat: minLat, lon: minLon },
    ne: { lat: maxLat, lon: maxLon },
  };
}

export function combineBoundingBoxes(
  boundingBoxes: GeoBoundingBox[]
): GeoBoundingBox {
  let minLon = 180,
    minLat = 90,
    maxLon = -180,
    maxLat = -90;

  for (const { sw, ne } of boundingBoxes) {
    minLon = Math.min(minLon, sw.lon);
    minLat = Math.min(minLat, sw.lat);
    maxLon = Math.max(maxLon, ne.lon);
    maxLat = Math.max(maxLat, ne.lat);
  }

  return {
    sw: { lat: minLat, lon: minLon },
    ne: { lat: maxLat, lon: maxLon },
  };
}

/**
 * @returns distance in meters
 */
export function getDistance(points: GeoPoint[]): number {
  if (points.length < 2) return 0;

  const turfLine = lineString(points.map((p) => [p.lon, p.lat]));

  return length(turfLine, { units: 'meters' });
}

/**
 * @returns area in square meters
 */
export function getArea(points: GeoPoint[]): number {
  if (points.length < 3) return 0;

  const turnPolygon = polygon([points.map((p) => [p.lon, p.lat])]);

  return area(turnPolygon);
}

const TILE_SIZE = 512; // Mapbox GL JS default tile size

function xToLongitude(x: number, origin: GeoPoint, zoom: number): number {
  // Calculate the map width in pixels at the given zoom level
  const mapWidth = TILE_SIZE * Math.pow(2, zoom);

  // Convert the origin longitude to the Mercator x coordinate
  const mercatorXRef = ((origin.lon + 180) / 360) * mapWidth;

  // Adjust the x-coordinate to account for the origin longitude
  const finalX = x + (mercatorXRef - mapWidth / 2);

  // Convert the x-coordinate to a longitude
  const mercatorX = finalX / mapWidth;
  const longitude = mercatorX * 360;

  return longitude;
}

function yToLatitude(y: number, origin: GeoPoint, zoom: number): number {
  const MAX_LATITUDE = 85.0511287798; // The maximum latitude for the Mercator projection
  const RADIANS_TO_DEGREES = 180 / Math.PI;
  const DEGREES_TO_RADIANS = Math.PI / 180;

  // Calculate the map height in pixels at the given zoom level
  const mapHeight = TILE_SIZE * Math.pow(2, zoom);

  // Calculate the Mercator Y value for the reference latitude at reference zoom level
  const latRad = origin.lat * DEGREES_TO_RADIANS;
  const mercatorYRef = Math.log(Math.tan(Math.PI / 4 + latRad / 2));
  const yOffset = mapHeight / 2 - (mapHeight * mercatorYRef) / (2 * Math.PI);

  // Adjust the y-coordinate from reference zoom level to the actual zoom level
  const adjustedY = y + yOffset;

  // Convert the y-coordinate to a latitude
  // The origin (0, 0) is at the top-left of the map
  // Convert y from pixels to the Mercator coordinate system
  const mercatorY = 2 * Math.PI * (0.5 - adjustedY / mapHeight);
  const latitude =
    RADIANS_TO_DEGREES * (2 * Math.atan(Math.exp(mercatorY)) - Math.PI / 2);

  // Clamp the latitude to the maximum latitude
  return Math.min(Math.max(latitude, -MAX_LATITUDE), MAX_LATITUDE);
}

export function pointToGeoPoint(
  point: Point,
  origin: GeoPoint,
  zoom: number
): GeoPoint {
  return {
    lat: yToLatitude(point.y, origin, zoom),
    lon: xToLongitude(point.x, origin, zoom),
  };
}
