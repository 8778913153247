import type {
  Notification,
  NotificationId,
} from '@pn/core/services/notifications/ports';
import { Default } from '@pn/ui/theme/themes/Default';
import {
  configureStore,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';

type NotificationPayloadAction = Omit<Notification, 'isOpen' | 'type'> & {
  type?: Notification['type'];
};

export type State = {
  notifications: Notification[];
};

const initialState: State = {
  notifications: [],
};

const slice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    add: (
      state,
      { payload: notification }: PayloadAction<NotificationPayloadAction>
    ) => {
      state.notifications.push({
        ...notification,
        type: notification.type ?? 'default',
        isOpen: true,
      });
    },
    hide: (
      state,
      { payload: notificationId }: PayloadAction<NotificationId>
    ) => {
      const notification = state.notifications.find(
        (notification) => notification?.id === notificationId
      );
      if (notification) notification.isOpen = false;
    },
    remove: (
      state,
      { payload: notificationId }: PayloadAction<NotificationId>
    ) => {
      state.notifications = state.notifications.filter(
        (notification) => notification?.id !== notificationId
      );
    },
  },
});

export const notificationStore = configureStore({
  reducer: slice.reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // allow non-serializable content
      serializableCheck: {
        ignoredActionPaths: ['payload.content', 'payload.action'],
        ignoredPaths: ['notifications'],
      },
    }),
});

export const getNotification = (id: NotificationId) => {
  return notificationStore
    .getState()
    .notifications.find((notification) => notification.id === id);
};
export const addNotification = (notification: NotificationPayloadAction) => {
  notificationStore.dispatch(slice.actions.add(notification));
};
export const hideNotification = (id: NotificationId) => {
  notificationStore.dispatch(slice.actions.hide(id));
};
export const removeNotification = (id: NotificationId) => {
  notificationStore.dispatch(slice.actions.remove(id));
};
/**
 * Allows the animation to finish before removing. Generally you'll want to use
 * this instead of `removeNotification` which is reserved for popping the stack
 * that's over the limit.
 */
export const gracefullyRemoveNotification = (id: NotificationId) => {
  hideNotification(id);
  setTimeout(
    () => removeNotification(id),
    Default.transitions.duration.standard
  );
};
