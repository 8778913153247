import { Box, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { DrawingMode } from '@pn/services/drawing';
import { useDrawing } from '@pn/services/drawing';
import { ColorPicker } from '@pn/ui/color-picker';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const FillColor = ({ featureLive }: Props) => {
  const {
    drawingMode,
    fillColor,
    setFillColor,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value = deriveFillColor(featureLive, drawingMode, fillColor);

  const handleChange = (color: string) => {
    setFillColor(color);
    drawingState.fillColor = color;

    if (featureLive && 'fillColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'fillColor', color);
      set(drawingState.features[featureLive.id], 'fillColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Fill
      </Typography>
      <ColorPicker showTransparent value={value} onChange={handleChange} />
    </Box>
  );
};

export function deriveFillColor(
  featureLive: CanvasFeature | undefined,
  drawingMode: DrawingMode,
  fillColor: string
): string | undefined {
  return featureLive &&
    'fillColor' in featureLive &&
    (featureLive.type === 'poly' ? !featureLive.arrow : true)
    ? featureLive.fillColor
    : ['line', 'rectangle', 'circle'].includes(drawingMode)
      ? fillColor
      : undefined;
}
