import { dependencies } from '@pn/core/dependencies';
import { handleError } from '@pn/core/errors/handleError';
import { dataDetailsActions, productionActions } from '@pn/core/storage';
import { apiProductionProvider } from '@pn/services/api/production/apiProductionProvider';
import { isString } from 'lodash-es';
import React from 'react';

export function useLeaseProduction(id: string | number | undefined) {
  const { isAuthenticated } = dependencies.useAuthenticationService();

  React.useEffect(() => {
    if (!isAuthenticated || !isString(id)) return;

    getLeaseProduction(id);
  }, [id, isAuthenticated]);
}

async function getLeaseProduction(id: string) {
  try {
    productionActions('lease').request();
    dataDetailsActions('lease').request();

    const { productionItems, details } =
      await apiProductionProvider.getLeaseProduction(id);

    productionActions('lease').receive(productionItems);
    dataDetailsActions('lease').receive(details);
  } catch (error) {
    handleError({
      error,
      onError: () => {
        productionActions('lease').error();
        dataDetailsActions('lease').error();
      },
      userFriendlyMessage: `Failed to get lease production`,
    });

    return undefined;
  }
}
