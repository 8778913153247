import type {
  DateWindowOption,
  ISODateTimeString,
} from '@pn/core/domain/types';
import { DateWindowDescriptions } from '@pn/core/domain/types';
import { format as formatDate } from 'date-fns';
import { uniq } from 'lodash-es';

export const DISPLAY_DATE_STRING_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_TIME_STRING_FORMAT = "yyyy-MM-dd 'at' h:mm aa";

export function formatISODateTimeString(string: ISODateTimeString) {
  return formatDate(new Date(string), DISPLAY_DATE_TIME_STRING_FORMAT);
}

export function formatISODateTimeStringAsDate(string: ISODateTimeString) {
  return formatDate(new Date(string), DISPLAY_DATE_STRING_FORMAT);
}

export const generateAllPossibleWindows = (
  relativeDate: Date = new Date()
): DateWindowOption[] => {
  return DateWindowDescriptions.map((description) => {
    const [startDate, endDate] = getDatesFromDateWindow(
      description,
      relativeDate
    );
    return { description, startDate, endDate };
  });
};

export function getDatesFromDateWindow(
  description: string,
  relativeDate: Date = new Date()
): [ISODateTimeString, ISODateTimeString] {
  const startDate = new Date(relativeDate);

  switch (description) {
    case 'last_two_days':
      startDate.setDate(relativeDate.getDate() - 2);
      break;
    case 'last_seven_days':
      startDate.setDate(relativeDate.getDate() - 7);
      break;
    case 'last_fourteen_days':
      startDate.setDate(relativeDate.getDate() - 14);
      break;
    case 'last_thirty_days':
      startDate.setDate(relativeDate.getDate() - 30);
      break;
    case 'last_three_months':
      startDate.setMonth(relativeDate.getMonth() - 3);
      break;
    case 'last_six_months':
      startDate.setMonth(relativeDate.getMonth() - 6);
      break;
    case 'last_year':
      startDate.setFullYear(relativeDate.getFullYear() - 1);
      break;
    case 'last_two_years':
      startDate.setFullYear(relativeDate.getFullYear() - 2);
      break;
    case 'last_three_years':
      startDate.setFullYear(relativeDate.getFullYear() - 3);
      break;
  }

  return [
    formatDate(startDate, DISPLAY_DATE_STRING_FORMAT),
    formatDate(relativeDate, DISPLAY_DATE_STRING_FORMAT),
  ];
}

export function formatUTCAsUsersLocalTime(utc: string | null) {
  if (!utc) return '';
  const date = new Date(utc);
  return date.toLocaleString();
}

export function generateAllPossibleMonths(
  startDate: string | undefined,
  endDate: string | undefined
): string[] {
  if (!startDate || !endDate) return [];
  if (startDate > endDate) return [];

  const start = new Date(startDate);
  const end = new Date(endDate);
  const months: string[] = [];

  while (start <= end) {
    // Add the current month in 'YYYY-MM' format to the array
    months.push(start.toISOString().slice(0, 7));

    // Move to the next month
    start.setMonth(start.getMonth() + 1);
  }
  return uniq(months);
}

export function generateDatesBetween(
  startDate: string,
  endDate: string
): string[] {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates: string[] = [];

  while (start <= end) {
    dates.push(start.toISOString().slice(0, 10));
    start.setDate(start.getDate() + 1);
  }

  return dates;
}
