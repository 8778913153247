import { alpha, Box, ButtonBase, Paper, Typography } from '@mui/material';
import type { CanvasFeature, CanvasIcon } from '@pn/core/domain/drawing';
import { useDrawing } from '@pn/services/drawing';
import { canvasIcons } from '@pn/services/drawing/canvasIcons';
import { CustomSvgIcon } from '@pn/ui/icons/CustomSVGIcon';
import { isNil, set } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const IconPicker = ({ featureLive }: Props) => {
  const { icon, setIcon, drawingMode, drawingState, historyManager, redraw } =
    useDrawing();

  const value =
    featureLive && featureLive.type === 'icon'
      ? featureLive.icon
      : drawingMode === 'icon'
        ? icon
        : undefined;

  const handleChange = (icon: CanvasIcon) => {
    setIcon(icon);
    drawingState.icon = icon;

    if (featureLive && featureLive.type === 'icon') {
      set(drawingState.featuresSelected[featureLive.id], 'icon', icon);
      set(drawingState.features[featureLive.id], 'icon', icon);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Icon
      </Typography>
      <Paper
        variant="outlined"
        sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 1 }}
      >
        {canvasIcons.map((canvasIcon) => (
          <IconToggleButton
            key={canvasIcon.id}
            selected={value.id === canvasIcon.id}
            onClick={() => handleChange(canvasIcon)}
          >
            <CustomSvgIcon
              pathData={[canvasIcon.pathData]}
              viewBox={canvasIcon.viewBox}
            />
          </IconToggleButton>
        ))}
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  button: {
    padding: theme.spacing(0.75),
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.action.active
        : theme.palette.text.secondary,
  },
  buttonSelected: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.text.primary
        : theme.palette.primary.contrastText,
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.2) // same as NavRailItem
        : theme.palette.primary.dark,
  },
}));

type IconToggleButtonProps = {
  children: React.ReactNode;
  selected: boolean;
  onClick: () => void;
};

const IconToggleButton = ({
  children,
  selected,
  onClick,
}: IconToggleButtonProps) => {
  const { classes, cx } = useStyles();

  return (
    <ButtonBase
      className={cx(classes.button, { [classes.buttonSelected]: selected })}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};
