import { createWorkspaceItem, getItemColor } from '@pn/core/domain/workspace';
import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { findOrThrow, nullToUndefined } from '@pn/core/utils/logic';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import { promptToQuery } from '@pn/services/gpt';
import { isEmpty } from 'lodash-es';

// TODO add `data_type` to the query and infer the source layer from the prompt
// TODO center map on the results
// TODO add support for dynamic styling

export function useGPTCommand() {
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const { fields, addFields } = useTableFields('wells');

  return async (...args: string[]): Promise<string> => {
    const prompt = args.join(' ');

    const gptResult = await promptToQuery(prompt); // TODO add error handling
    console.log('gptResult', gptResult);

    if (isEmpty(gptResult.filters))
      return gptResult.insufficientSetupReason ?? 'No filters to visualize.';

    /* Ensure all returned fields are shown in the bottom table */

    const requiredFields = [
      ...gptResult.filters.map((f) => f.field),
      ...gptResult.sorts.map((s) => s.field),
    ];
    const fieldsToAdd = requiredFields.filter((f) => !fields.includes(f));
    addFields(fieldsToAdd);

    /* Visualize the query */

    const sourceItem = findOrThrow(
      allWorkspaceItems,
      (item) => item.id === 'wells'
    );

    const temporaryFilterItems = allWorkspaceItems.filter(
      (item) => item.isTemporary && !isEmpty(item.query.filters)
    );

    const newItem = createWorkspaceItem(
      {
        source: 'item',
        sourceItem,
        name: gptResult.name,
        extraStyle: {
          color:
            gptResult.color ??
            muiColorPalette.getFilterColor(
              temporaryFilterItems.map((item) => getItemColor(item) as string)
            ),
        },
        queryOptions: {
          sorts: gptResult.sorts,
          filters: gptResult.filters.map((f) => ({
            field: f.field,
            operator: f.operator,
            value: nullToUndefined(f.value),
            unitSystem: nullToUndefined(f.unitSystem),
          })),
          filtersLinkOperator: gptResult.filtersLinkOperator,
        },
      },
      user
    );

    workspaceActions().add(newItem);
    workspaceActions().addToWorkspace(newItem.id);
    workspaceActions().select(newItem.id);

    return (
      '====================\n' +
      (gptResult.insufficientSetupReason ?? 'Success.')
    );
  };
}
