import type { DataItem, MappingItem } from '@pn/core/domain/data';
import type { UnitSystem } from '@pn/core/domain/types';
import type { IDownloadService } from '@pn/core/services/exports/ports';
import { downloadAsCsv } from '@pn/services/exports/csvExportService';
import { csvDataItemMapper } from '@pn/services/exports/data/csvDataItemMapper';
import { csvQueryFieldsMapper } from '@pn/services/exports/data/csvQueryFieldsMapper';
import { downloadAsGeoJSON } from '@pn/services/exports/jsonExportService';
import { dataToGeoJson } from '@pn/services/local/data/geoJsonToDataConverter';

export function downloadDataItems(params: {
  data: DataItem[];
  fields: string[];
  mapping: MappingItem[];
  unitSystem: UnitSystem;
}): IDownloadService {
  const { data, fields, mapping, unitSystem } = params;

  return {
    csv: ({ fileName }) => {
      const csvData = data.map(
        csvDataItemMapper(mapping, unitSystem).toTargetDataItem
      );
      const csvFields = csvQueryFieldsMapper(
        mapping,
        unitSystem
      ).toTargetFields(fields);

      downloadAsCsv(csvData, { fields: csvFields, fileName });
    },
    json: () => {
      throw new Error('Not implemented');
    },
    geojson: ({ fileName }) => {
      const geojson = dataToGeoJson(data, mapping);
      downloadAsGeoJSON(geojson, { fileName });
    },
  };
}
