import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { handleError } from '@pn/core/errors/handleError';
import { useBufferZones } from '@pn/core/operations/mapInteractions/bufferZones';
import { useAccess } from '@pn/core/permissions/access';
import { Code } from '@pn/ui/custom-components/Code';
import React from 'react';
import { notificationService } from 'src/application/externalDependencies';

export const BufferZonesButton = () => {
  const access = useAccess();

  const { disabled, generateBufferZones } = useBufferZones();

  const [isOpen, setIsOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [primary, setPrimary] = React.useState(500);
  const [secondary, setSecondary] = React.useState(1000);
  const [inProgress, setInProgress] = React.useState(false);

  const isNonPositive = primary <= 0 || (checked && secondary <= 0);
  const isFlipped = checked && primary >= secondary;

  const handleCreateBufferZones = async () => {
    if (access('bufferZones').notify().denied()) return;

    setInProgress(true);
    notificationService.removeNotification('buffer-zones-report');

    try {
      const downloadReport = await generateBufferZones({
        inputDistances: checked ? [primary, secondary] : [primary],
        listNames: [`Primary ${primary} m`, `Secondary ${secondary} m`],
      });

      setIsOpen(false);

      notificationService.render({
        content: 'Buffer zones report',
        action: (
          <Button
            size="small"
            onClick={() => {
              downloadReport('Buffer zones.csv');
              notificationService.removeNotification('buffer-zones-report');
            }}
          >
            Download
          </Button>
        ),
        options: {
          id: 'buffer-zones-report',
          showCloseButton: true,
        },
      });
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Buffer zones generation failed',
      });
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <Tooltip title="Buffer zones" placement="left">
        <Box component="span">
          <Fab
            aria-label="generate buffer zones"
            size="small"
            color="default"
            disabled={disabled}
            onClick={() => setIsOpen(true)}
          >
            <WifiTetheringIcon sx={{ transform: 'rotate(180deg)' }} />
          </Fab>
        </Box>
      </Tooltip>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>Buffer zones</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <DialogContentText>
            Select one or multiple wells to generate buffer zones around them.
            Press <Code dense>Ctrl + P</Code> to print the result.
          </DialogContentText>
          <TextField
            fullWidth
            type="number"
            label="Primary distance"
            value={primary}
            onChange={(e) => setPrimary(Number(e.target.value))}
            slotProps={{
              input: {
                endAdornment: 'm',
              },
            }}
            sx={{ mt: 1.5, maxWidth: 200 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label="Include secondary"
          />
          <TextField
            fullWidth
            type="number"
            label="Secondary distance"
            disabled={!checked}
            value={secondary}
            onChange={(e) => setSecondary(Number(e.target.value))}
            slotProps={{
              input: {
                endAdornment: 'm',
              },
            }}
            sx={{ maxWidth: 200 }}
          />

          {isNonPositive && (
            <Alert severity="warning">Distance must be positive</Alert>
          )}
          {!isNonPositive && isFlipped && (
            <Alert severity="warning">
              Primary distance must be smaller than secondary
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateBufferZones}
            disabled={isNonPositive || isFlipped}
          >
            Generate
          </Button>
        </DialogActions>
        {inProgress ? <LinearProgress /> : <Box height={4} />}
      </Dialog>
    </>
  );
};
