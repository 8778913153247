import { dependencies } from '@pn/core/dependencies';
import { formatDataType, type DataType } from '@pn/core/domain/data';
import type { NotificationId } from '@pn/core/services/notifications/ports';
import { isNil } from 'lodash-es';

export function showPersistentStreamingNotification(params: {
  action: string;
  dataType: DataType;
  totalCount: number;
}): NotificationId | undefined {
  const { action, dataType, totalCount } = params;
  const { notificationService } = dependencies;

  return notificationService.render({
    content: `${action} ${totalCount.toLocaleString()} ${formatDataType(dataType)}...`,
    options: {
      showSpinner: true,
    },
  });
}

export function removePersistentStreamingNotification(
  notificationId: NotificationId | undefined
): void {
  const { notificationService } = dependencies;

  if (!isNil(notificationId)) {
    notificationService.removeNotification(notificationId);
  }
}
