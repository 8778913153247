import { CssBaseline, type Theme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import type { AppName } from '@pn/core/dependencies';
import { isString } from 'lodash-es';
import React from 'react';
import { themes } from './muiThemes';
import { webStorageService } from '@pn/services/storage/webStorageService';

export const pnThemes = [
  'Default',
  'Petro Night',
  'BOE Light',
  'BOE Dark',
  'Hacker',
  'Holiday',
] as const;
export type PNTheme = (typeof pnThemes)[number];

export const productThemes: Record<AppName, PNTheme[]> = {
  maps: ['Default', 'Petro Night'],
  intel: ['BOE Light', 'BOE Dark', 'Petro Night'],
  formations: ['Default', 'Petro Night'],
  admin: [...pnThemes],
};

type ContextType = {
  pnTheme: PNTheme;
  theme: Theme;
  setPNTheme: (pnTheme: PNTheme) => void;
};

const PNThemeContext = React.createContext({} as ContextType);

export const usePNThemeContext = () => React.useContext(PNThemeContext);

type Props = {
  children: React.ReactNode;
  defaultPNTheme?: PNTheme;
  persist?: boolean;
};

export const PetroNinjaThemeProvider = ({
  children,
  defaultPNTheme = 'Default',
  persist = true,
}: Props) => {
  const [pnTheme, setPNTheme] = React.useState<PNTheme>(defaultPNTheme);

  const petroNinjaTheme = usePetroNinjaTheme(pnTheme);

  /**
   * We run this effect to support SSR where localStorage is not available.
   */
  React.useEffect(() => {
    if (!persist) return;

    const lsPNTheme = webStorageService('pn').get('theme');
    if (isPNTheme(lsPNTheme)) {
      setPNTheme(lsPNTheme);
    } else {
      setPNTheme(defaultPNTheme);
    }
  }, [persist, defaultPNTheme]);

  React.useEffect(() => {
    if (!persist) return;

    webStorageService('pn').set('theme', pnTheme);
  }, [persist, pnTheme]);

  return (
    <PNThemeContext.Provider
      value={{ pnTheme, theme: petroNinjaTheme, setPNTheme }}
    >
      <MuiThemeProvider theme={petroNinjaTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </PNThemeContext.Provider>
  );
};

function usePetroNinjaTheme(pnTheme: PNTheme): Theme {
  return React.useMemo(() => {
    const theme = themes[pnTheme];
    console.log(`%cMUI theme (${pnTheme})`, 'color: #607D8B', theme);
    return theme;
  }, [pnTheme]);
}

export function isPNTheme(arg: unknown): arg is PNTheme {
  return isString(arg) && pnThemes.includes(arg as PNTheme);
}
