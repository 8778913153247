import WarningIcon from '@mui/icons-material/Warning';
import { Box, Tooltip, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { getZoom, useDrawing } from '@pn/services/drawing';
import { NumericInputWithToggles } from '@pn/ui/inputs/NumericInputWithToggles';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const IconSize = ({ featureLive }: Props) => {
  const {
    drawingMode,
    iconSize,
    setIconSize,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value =
    featureLive && 'iconSize' in featureLive
      ? featureLive.iconSize * getZoom()
      : drawingMode === 'icon'
        ? iconSize
        : undefined;

  const handleChange = (size: number) => {
    setIconSize(size);
    drawingState.iconSize = size;

    if (featureLive && 'iconSize' in featureLive) {
      const zoomedSize = size / getZoom();

      set(
        drawingState.featuresSelected[featureLive.id],
        'iconSize',
        zoomedSize
      );
      set(drawingState.features[featureLive.id], 'iconSize', zoomedSize);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Size
      </Typography>
      <Box display="flex" alignItems="center" gap="29px">
        <NumericInputWithToggles
          toggles={[
            { label: 'S', value: 16 },
            { label: 'M', value: 24 },
            { label: 'L', value: 48 },
            { label: 'XL', value: 96 },
          ]}
          value={value}
          units="px"
          onChange={handleChange}
        />
        <Tooltip title="Relative to the current zoom level">
          <WarningIcon color="primary" />
        </Tooltip>
      </Box>
    </Box>
  );
};
