import { dependencies } from '@pn/core/dependencies';
import type { CanvasFeature, Transformation } from '@pn/core/domain/drawing';
import { toGeoPoint, type GeoPoint } from '@pn/core/domain/geography';
import type { MapFeature } from '@pn/core/domain/map';
import {
  getCanvasBoundingBox,
  scalePoint,
  toGeoBoundingBox,
  type DrawingState,
} from '@pn/services/drawing';
import { geoBoundingBoxToMapboxBbox } from '@pn/services/map/mapbox/mapboxUtils';
import {
  isMapboxDataFeature,
  mapboxMapFeatureMapper,
} from '@pn/services/map/mapbox/mappers/mapboxMapFeatureMapper';
import { canvasFeatureToGeoJSON, isWithin } from '@pn/services/utils/geojson';
import { isEmpty } from 'lodash-es';

export const REFERENCE_PT = toGeoPoint(53.5, -113.5); // Edmonton
export const REFERENCE_ZOOM = 10;

export function getZoom(): number {
  const { map } = dependencies;

  return Math.pow(2, map._native.getZoom() - REFERENCE_ZOOM);
}

export function computeMapTransformation(
  initGeoPoint: GeoPoint, // NW corner of the map
  withDevicePixelRatio = true
): Transformation {
  const { map } = dependencies;

  const currentNW = map._native.getBounds().getNorthWest();

  const multiplier = withDevicePixelRatio ? window.devicePixelRatio : 1;

  const initPoint = scalePoint(map._native.project(initGeoPoint), multiplier);
  const currentPoint = scalePoint(map._native.project(currentNW), multiplier);

  const scaleFactor = getZoom() * multiplier;

  return {
    dx: (initPoint.x - currentPoint.x) / scaleFactor,
    dy: (initPoint.y - currentPoint.y) / scaleFactor,
    scale: scaleFactor,
  };
}

export const getMeasurementStyle = (scale = 1) => ({
  strokeColor: '#000',
  strokeWidth: 2 / scale,
  opacity: 1,
});

export const getMapSelectionStyle = (scale = 1) => ({
  strokeColor: 'hsl(200, 18%, 46%)',
  strokeWidth: 2 / scale,
  fillColor: 'hsla(200, 18%, 46%, 0.15)',
});

export function convertToMapSelection(
  feature: CanvasFeature,
  processMapSelection: (params: {
    features: MapFeature[];
    append: boolean;
  }) => void
): void {
  const { map, notificationService } = dependencies;

  const canvasBbox = getCanvasBoundingBox(feature);
  const geoBoundingBox = toGeoBoundingBox(
    canvasBbox,
    REFERENCE_PT,
    REFERENCE_ZOOM
  );
  const mapboxBbox = geoBoundingBoxToMapboxBbox(map._native, geoBoundingBox);

  const matchedMapFeatures = map._native.queryRenderedFeatures(mapboxBbox);

  const geoFeature = canvasFeatureToGeoJSON(feature);
  const intersectingMapFeatures = matchedMapFeatures.filter(
    (f) => isMapboxDataFeature(f) && isWithin(geoFeature.geometry, f.geometry)
  );

  if (isEmpty(intersectingMapFeatures)) {
    return notificationService.notify('No selectable features available');
  }

  processMapSelection({
    features: intersectingMapFeatures.map(
      mapboxMapFeatureMapper.toDomainMapFeature
    ),
    append: false,
  });
}

export function clearPreviousMapSelections(drawingState: DrawingState): void {
  const featureIdsDeleted = new Set<string>();

  Object.values(drawingState.features).forEach((feature) => {
    if (isMapSelectionFeature(feature)) {
      delete drawingState.features[feature.id];
      delete drawingState.paths[feature.id];

      featureIdsDeleted.add(feature.id);
    }
  });

  drawingState.order = drawingState.order.filter(
    (id) => !featureIdsDeleted.has(id)
  );
}

function isMapSelectionFeature(feature: CanvasFeature): boolean {
  const isRectangleSelection =
    feature.type === 'poly' &&
    feature.subType === 'rectangle_selection' &&
    feature.itemId === '';

  const isCircleSelection = feature.type === 'circle' && feature.itemId === '';

  return isRectangleSelection || isCircleSelection;
}
