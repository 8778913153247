import { dependencies } from '@pn/core/dependencies';
import type { CircleFeature } from '@pn/core/domain/drawing';
import { areClose, type Point } from '@pn/core/domain/point';
import { useMapSelectionProcessor } from '@pn/core/operations/mapInteractions';
import { generateId } from '@pn/core/utils/id';
import {
  REFERENCE_PT,
  clearPreviousMapSelections,
  computeMapTransformation,
  convertToMapSelection,
  drawFeature,
  getContext,
  getMapSelectionStyle,
  scalePoint,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useCircleTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const {
    liveCanvasRef,
    drawingMode,
    setDrawingMode,
    strokeColor,
    strokeWidth,
    fillColor,
    opacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const isSelecting = drawingMode === 'circle_select';
  const isDrawing = isDrawingPanelOpen && drawingMode === 'circle';

  useHotkeys(
    'esc',
    () => {
      if (isSelecting) setDrawingMode('select');
    },
    [isSelecting]
  );

  const { processMixedMapSelection } = useMapSelectionProcessor();

  React.useEffect(() => {
    if (!isSelecting && !isDrawing) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const ctx = getContext(liveCanvasRef.current);

    let altKey = false;
    let startPoint = { x: 0, y: 0 };
    let point = { x: 0, y: 0 };
    let id = '';

    const onKeyDownOrUp = (e: KeyboardEvent) => {
      if (e.key !== 'Alt' || e.repeat) return;

      /**
       * Prevents the browser from running the default Alt key behavior.
       * If this is not done, all subsequent events will be ignored.
       */
      e.preventDefault();

      altKey = e.type === 'keydown';

      if (drawingState.isCustomPanning || !drawingState.isDrawing) return;

      const { center, radius } = calculateCircleParams({
        strategy: altKey ? 'center' : 'circumscribe',
        startPoint,
        point,
      });

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const feature: CircleFeature = {
        type: 'circle',
        id,
        opacity,
        itemId,
        center,
        strokeColor,
        strokeWidth,
        fillColor,
        radius,
        isVisible: true,
        ...(isSelecting ? getMapSelectionStyle() : {}),
      };

      drawFeature(
        ctx,
        drawingState.displayMeasurements || isSelecting
          ? {
              ...feature,
              measurements: generateFeatureMeasurements(feature, {
                local: true,
                cursor: point,
              }),
            }
          : feature
      );
    };

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning) return;

      drawingState.isDrawing = true;
      id = generateId();

      startPoint = scalePoint(e.point);
      point = scalePoint(e.point);

      map.disableMovement();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || !drawingState.isDrawing) return;

      const bbox = map._native.getContainer().getBoundingClientRect();
      point = scalePoint({
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      });

      const { center, radius } = calculateCircleParams({
        strategy: altKey ? 'center' : 'circumscribe',
        startPoint,
        point,
      });

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const feature: CircleFeature = {
        type: 'circle',
        id,
        opacity,
        center,
        strokeColor,
        strokeWidth,
        fillColor,
        radius,
        itemId,
        isVisible: true,
        ...(isSelecting ? getMapSelectionStyle() : {}),
      };

      drawFeature(
        ctx,
        drawingState.displayMeasurements || isSelecting
          ? {
              ...feature,
              measurements: generateFeatureMeasurements(feature, {
                local: true,
                cursor: point,
              }),
            }
          : feature
      );
    };

    const onMouseUp = (e: MouseEvent) => {
      if (!drawingState.isDrawing) return;

      const bbox = map._native.getContainer().getBoundingClientRect();
      point = scalePoint({
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      });

      if (areClose(startPoint, point, 2)) {
        drawingState.isDrawing = false;

        redraw();

        map.enableMovement();

        return;
      }

      const transformation = computeMapTransformation(REFERENCE_PT);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      drawingState.isDrawing = false;

      if (isSelecting) clearPreviousMapSelections(drawingState);

      const { center, radius } = calculateCircleParams({
        strategy: altKey ? 'center' : 'circumscribe',
        startPoint,
        point,
      });

      const feature: CircleFeature = {
        id,
        type: 'circle',
        center: transformPoint(center, inverseTransformation),
        strokeColor,
        strokeWidth: strokeWidth / transformation.scale,
        fillColor,
        radius: radius / transformation.scale,
        opacity,
        itemId,
        isVisible: true,
        ...(isSelecting ? getMapSelectionStyle(transformation.scale) : {}),
      };

      if (isSelecting) {
        convertToMapSelection(feature, processMixedMapSelection);

        setDrawingMode('select');
      } else {
        drawingState.features[id] = drawingState.displayMeasurements
          ? {
              ...feature,
              measurements: generateFeatureMeasurements(feature, {
                cursor: transformPoint(point, inverseTransformation),
              }),
            }
          : feature;
        drawingState.order.push(id);
      }

      redraw();

      historyManager.add(drawingState);

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', onKeyDownOrUp);
    document.addEventListener('keyup', onKeyDownOrUp);

    return () => {
      drawingState.isDrawing = false;

      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('keydown', onKeyDownOrUp);
      document.removeEventListener('keyup', onKeyDownOrUp);
    };
  }, [
    isDrawing,
    isSelecting,
    drawingMode,
    setDrawingMode,
    strokeColor,
    strokeWidth,
    fillColor,
    opacity,
    itemId,
    processMixedMapSelection,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

type CircleParams = {
  center: Point;
  radius: number;
};

/**
 * Both strategies replicate Excalidraw's behavior.
 */
function calculateCircleParams(params: {
  strategy: 'circumscribe' | 'center';
  startPoint: Point;
  point: Point;
}): CircleParams {
  const { strategy, startPoint, point } = params;

  const dx = startPoint.x - point.x;
  const dy = startPoint.y - point.y;

  switch (strategy) {
    case 'circumscribe': {
      const radius = Math.max(Math.abs(dx), Math.abs(dy)) / 2;

      return {
        center: {
          x: startPoint.x - radius * Math.sign(dx),
          y: startPoint.y - radius * Math.sign(dy),
        },
        radius,
      };
    }
    case 'center': {
      return {
        center: startPoint,
        radius: Math.max(Math.abs(dx), Math.abs(dy)),
      };
    }
  }
}
