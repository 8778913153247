import type { SvgIconProps } from '@mui/material';
import type { User } from '@pn/core/domain/user';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { customRoutes } from './customRoutes';
import { dispositionsRoutes } from './dispositionsRoutes';
import { facilitiesRoutes } from './facilitiesRoutes';
import { mineralRightsRoutes } from './mineralRightsRoutes';
import { pipelinesRoutes } from './pipelinesRoutes';
import { poolsRoutes } from './poolsRoutes';
import { unitsRoutes } from './unitsRoutes';
import { wellsRoutes, wellsUsaRoutes } from './wellsRoutes';

export type MainPanelRoute = {
  path: string;
  title: string;
  Icon: React.ElementType<SvgIconProps> | undefined;
  IconSelected: React.ElementType<SvgIconProps> | undefined;
  getComponent: (params: { item: WorkspaceItem }) => JSX.Element;
  shouldShow?: (params: {
    item: WorkspaceItem;
    user: User | undefined;
  }) => boolean;
};

const mainPanelRoutes = (
  dataType: string
): Record<string, MainPanelRoute[]> => ({
  wells: wellsRoutes(dataType),
  wells_usa: wellsUsaRoutes,
  pipelines: pipelinesRoutes,
  facilities: facilitiesRoutes,
  mineral_rights: mineralRightsRoutes,
  units: unitsRoutes,
  pools: poolsRoutes,
  dispositions: dispositionsRoutes,
});

export function getMainPanelRoutes(
  dataType: string,
  routesType = dataType
): MainPanelRoute[] {
  return mainPanelRoutes(dataType)[routesType] ?? customRoutes;
}
