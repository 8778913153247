import { Box, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { DrawingMode } from '@pn/services/drawing';
import { useDrawing } from '@pn/services/drawing';
import { ColorPicker } from '@pn/ui/color-picker';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const TextColor = ({ featureLive }: Props) => {
  const {
    drawingMode,
    textColor,
    setTextColor,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value = deriveTextColor(featureLive, drawingMode, textColor);

  const handleChange = (color: string) => {
    setTextColor(color);
    drawingState.textColor = color;

    if (featureLive && 'textColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'textColor', color);
      set(drawingState.features[featureLive.id], 'textColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Text
      </Typography>
      <ColorPicker value={value} onChange={handleChange} />
    </Box>
  );
};

export function deriveTextColor(
  featureLive: CanvasFeature | undefined,
  drawingMode: DrawingMode,
  textColor: string
): string | undefined {
  return featureLive && 'textColor' in featureLive
    ? featureLive.textColor
    : drawingMode === 'text'
      ? textColor
      : undefined;
}
