import { Box, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { DrawingMode } from '@pn/services/drawing';
import { useDrawing } from '@pn/services/drawing';
import { ColorPicker } from '@pn/ui/color-picker';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const StrokeColor = ({ featureLive }: Props) => {
  const {
    drawingMode,
    strokeColor,
    setStrokeColor,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value = deriveStrokeColor(featureLive, drawingMode, strokeColor);

  const handleChange = (color: string) => {
    setStrokeColor(color);
    drawingState.strokeColor = color;

    if (featureLive && 'strokeColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'strokeColor', color);
      set(drawingState.features[featureLive.id], 'strokeColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Stroke
      </Typography>
      <ColorPicker value={value} onChange={handleChange} />
    </Box>
  );
};

export function deriveStrokeColor(
  featureLive: CanvasFeature | undefined,
  drawingMode: DrawingMode,
  strokeColor: string
): string | undefined {
  return featureLive && 'strokeColor' in featureLive
    ? featureLive.strokeColor
    : ['freehand', 'line', 'arrow', 'rectangle', 'circle'].includes(drawingMode)
      ? strokeColor
      : undefined;
}
