import type { CanvasFeature } from '@pn/core/domain/drawing';
import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isArray, isEmpty, isNil, isObject, isString } from 'lodash-es';

export type Layer = {
  id: string;
  name?: string;
  type: LayerType;
  style: LayerStyle;
  renderAsPoints?: boolean;
  source: GeoJSONSource | VectorSource | MediaSource;
  sourceLayer: string | undefined;
  sourceField?: string;
  metadata?: {
    type: 'drawing';
    features: CanvasFeature[];
  };
};

type GeoJSONSource = {
  type: 'geojson';
  data: string | GeoJSON.FeatureCollection | GeoJSON.Feature | GeoJSON.Geometry;
};
type VectorSource =
  | { type: 'vector'; url: string }
  | { type: 'vector'; tiles: string[] };
type MediaSource = {
  type: 'raster' | 'raster-dem' | 'image' | 'video';
  [key: string]: unknown;
};
export function isLayerSource(arg: unknown): arg is Layer['source'] {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'type', isString) &&
    ['geojson', 'vector', 'raster', 'raster-dem', 'image', 'video'].includes(
      arg.type
    ) &&
    (arg.type === 'geojson' ? hasKey(arg, 'data') && !isNil(arg.data) : true)
  );
}

export type CustomSVGIconData = {
  pathData: string[];
  viewBox?: string;
};

export type FilterProperty = {
  field: string;
  options: Record<
    string,
    {
      label: string;
      icon?: CustomSVGIconData;
      value: boolean;
    }
  >;
};

export enum LayerType {
  Icon = 'icon', // symbol in Mapbox
  Text = 'text', // symbol in Mapbox
  Line = 'line',
  Polygon = 'polygon', // fill in Mapbox
  Circle = 'circle',
  Raster = 'raster',
  Mixed = 'mixed', // does not correspond to a Mapbox layer type (mapped to "custom")
}

export function formatLayerType(
  type: LayerType,
  options?: { plural?: boolean }
): string {
  const { plural = false } = options ?? {};

  switch (type) {
    case LayerType.Icon:
      return plural ? 'Icons' : 'Icon';
    case LayerType.Text:
      return 'Text';
    case LayerType.Line:
      return plural ? 'Lines' : 'Line';
    case LayerType.Polygon:
      return plural ? 'Polygons' : 'Line';
    case LayerType.Circle:
      return plural ? 'Circles' : 'Circle';
    case LayerType.Raster:
      return 'Raster';
    case LayerType.Mixed:
      return 'Mixed';
  }
}

export function isInteractableLayer(layer: Layer): boolean {
  return ![LayerType.Raster, LayerType.Mixed].includes(layer.type);
}

export function isMapboxLayer(arg: unknown): arg is mapboxgl.Layer {
  return (
    isObject(arg) &&
    hasKey(arg, 'id') &&
    hasKey(arg, 'type') &&
    hasKey(arg, 'source')
  );
}

export type AnnotationFeature = GeoJSON.Feature<
  GeoJSON.Geometry,
  {
    lineColor?: string;
    lineWidth?: number;
    fillColor?: string;
    fillOpacity?: number;
    textField?: string;
    textSize?: number;
    textColor?: string;
  }
>;

type AnnotationSource = Omit<GeoJSONSource, 'data'> & {
  data: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    AnnotationFeature['properties']
  >;
};
export function isAnnotationSource(arg: unknown): arg is AnnotationSource {
  return (
    isObject(arg) &&
    hasKey(arg, 'type') &&
    arg.type === 'geojson' &&
    hasKeyWithType(arg, 'data', isObject) &&
    hasKey(arg.data, 'type') &&
    arg.data.type === 'FeatureCollection' &&
    hasKeyWithType(arg.data, 'features', isArray)
  );
}

export type LayerStyle = Partial<Record<LayerStyleProperty, LayerStyleValue>>;

const layerStyleProperties = [
  'color',
  'opacity',
  'size',
  'radius',
  'width',
  'maxWidth',
  'dashArray',
  'lineCap',
  'lineJoin',
  'outlineColor',
  'strokeColor',
  'strokeWidth',
  'placement',
  'lineHeight',
  'haloColor',
  'haloWidth',
  'haloBlur',
  'padding',
  'image',
  'field',
  'pattern',
  'font',
  'anchor',
  'offset',
  'allowOverlap',
  'ignorePlacement',
  'symbolSortKey',
  'symbolZOrder',
] as const;
export type LayerStyleProperty = (typeof layerStyleProperties)[number];
export function isLayerStyleProperty(key: string): key is LayerStyleProperty {
  return layerStyleProperties.includes(key as LayerStyleProperty);
}

export type LayerStyleValue = unknown; // since we currently use unmapped Mapbox-compatible values, this can be anything

type ValueAndColor = { value: string; color: string };
type ColorMapping = unknown[];

export function isColorMapping(arg: unknown): arg is ColorMapping {
  return (
    Array.isArray(arg) &&
    arg.length >= 3 &&
    arg[0] === 'match' &&
    Array.isArray(arg[1])
  );
}

// get style property value and its type
export function getStyleColor(
  style: LayerStyle
): string | ColorMapping | undefined {
  const color = style?.color;
  if (isString(color)) {
    return color;
  } else if (isColorMapping(color)) {
    return color;
  } else {
    return undefined;
  }
}

export function generateColorMappingByProperty(
  valuesAndColors: ValueAndColor[],
  propertyGetter: unknown[], // e.g. ['get', property]
  defaultColor = '#000'
): ColorMapping {
  return [
    'match',
    propertyGetter,
    ...valuesAndColors.flatMap(({ value, color }) => [value, color]), // pairs of value and corresponding color
    defaultColor,
  ];
}

// TODO get rid of this?
export function getValuesAndColorMapping(
  colorMapping: ColorMapping
): [ValueAndColor[], string] {
  const valuesAndColors: ValueAndColor[] = [];
  // @ts-expect-error no idea what's going on here
  const field = colorMapping[1][1] as string; // FIXME fix this type or get rid of this function
  for (let i = 2; i < colorMapping.length; i += 2) {
    valuesAndColors.push({
      value: colorMapping[i] as string,
      color: colorMapping[i + 1] as string,
    });
  }
  return [valuesAndColors.filter(({ color }) => !isEmpty(color)), field];
}
