import { dependencies } from '@pn/core/dependencies';
import type { IconFeature } from '@pn/core/domain/drawing';
import { generateId } from '@pn/core/utils/id';
import {
  computeMapTransformation,
  drawFeature,
  getContext,
  REFERENCE_PT,
  scalePoint,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';

export function useIconTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const {
    liveCanvasRef,
    drawingMode,
    icon,
    iconColor,
    iconSize,
    opacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode !== 'icon') return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    // const ctx = getContext(liveCanvasRef.current);

    // const onMouseMove = (e: MouseEvent) => {
    //   if (drawingState.isCustomPanning) return;

    //   const bbox = map._native.getContainer().getBoundingClientRect();
    //   const point = scalePoint({
    //     x: e.clientX - bbox.left,
    //     y: e.clientY - bbox.top,
    //   });

    //   ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    //   const feature: IconFeature = {
    //     id: '',
    //     type: 'icon',
    //     position: point,
    //     icon,
    //     iconColor: '#888',
    //     iconSize: iconSize,
    //     opacity: 0.5,
    //     itemId,
    //     isVisible: true,
    //   };

    //   drawFeature(ctx, feature);
    // };

    const onClick = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning) return;
      if (icon.pathData === '') return;

      const transformation = computeMapTransformation(REFERENCE_PT);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const id = generateId();
      const center = scalePoint(e.point);

      const feature: IconFeature = {
        id,
        type: 'icon',
        position: transformPoint(center, inverseTransformation),
        icon,
        iconColor,
        iconSize: iconSize / transformation.scale,
        opacity,
        itemId,
        isVisible: true,
      };

      drawingState.features[id] = feature;
      drawingState.order.push(id);

      redraw();

      historyManager.add(drawingState);
    };

    mapboxMap.on('click', onClick);
    // document.addEventListener('mousemove', onMouseMove);

    return () => {
      mapboxMap.off('click', onClick);
      // document.removeEventListener('mousemove', onMouseMove);
    };
  }, [
    isDrawingPanelOpen,
    drawingMode,
    icon,
    iconColor,
    iconSize,
    opacity,
    itemId,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}
