import { Box, Typography } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { DrawingMode } from '@pn/services/drawing';
import { useDrawing } from '@pn/services/drawing';
import { ColorPicker } from '@pn/ui/color-picker';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const IconColor = ({ featureLive }: Props) => {
  const {
    drawingMode,
    iconColor,
    setIconColor,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value = deriveIconColor(featureLive, drawingMode, iconColor);

  const handleChange = (color: string) => {
    setIconColor(color);
    drawingState.iconColor = color;

    if (featureLive && 'iconColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'iconColor', color);
      set(drawingState.features[featureLive.id], 'iconColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Color
      </Typography>
      <ColorPicker value={value} onChange={handleChange} />
    </Box>
  );
};

export function deriveIconColor(
  featureLive: CanvasFeature | undefined,
  drawingMode: DrawingMode,
  iconColor: string
): string | undefined {
  return featureLive && 'iconColor' in featureLive
    ? featureLive.iconColor
    : drawingMode === 'icon'
      ? iconColor
      : undefined;
}
