import wellsElasticMapping from '@pn/resources/elastic-mappings/wells.jsonc';
import { elasticToDomainMapping } from '@pn/services/api/data/elastic';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import {
  GPTResultSchema,
  type GPTResultySchemaType,
} from '@pn/services/gpt/gptQuerySchema';
import assert from 'assert';
import { format } from 'date-fns';
import OpenAI from 'openai';
import { zodToJsonSchema } from 'zod-to-json-schema';

export function getSystemMessage(): string {
  const gptMapping = elasticToDomainMapping(wellsElasticMapping).map(
    (m: any) => {
      return {
        field: m.field,
        label: m.label,
        type: m.domainType === 'SIUnit' ? 'Unit' : m.domainType,
        symbol: m.domainTypeAttributes?.symbol,
        options: m.domainTypeAttributes?.options,
        isNotSortable: m.isNotSortable ? true : undefined,
        hint: m.hint,
      };
    }
  );

  const message =
    `You are an oil & gas expert tasked with generating JSON output based on a schema.\
    Today's date is ${format(new Date(), 'yyyy-MM-dd')}.\
    Use the mapping below to extract relevant fields before generating the output.\
    Make sure to match the domain types with their relevant operators.\
    For strings use 'contains', 'notContains'; for numbers: '>=', '<=', '==';\
    for dateStrings: 'is', 'onOrAfter', 'onOrBefore'; for booleans: 'is'.\
    Perform the conversion of numeric units to match their 'symbol' if present.\
    Convert all dates into the 'yyyy-MM-dd' format.\
    Set 'insufficientSetup' to true if the mapping is inadequate to fully satisfy the user's request\
    and explain your reasoning in 'insufficientSetupReason'.\\n\\n`.replace(
      / +/g,
      ' '
    ) + JSON.stringify(gptMapping);

  return message;
}

export async function promptToQuery(
  prompt: string
): Promise<GPTResultySchemaType> {
  const request: OpenAI.Chat.ChatCompletionCreateParams = {
    model: 'gpt-4o-2024-08-06',
    temperature: 0.1,
    messages: [
      {
        role: 'system',
        content: getSystemMessage(),
      },
      {
        role: 'user',
        content: prompt,
      },
    ],
    response_format: {
      type: 'json_schema',
      json_schema: {
        name: 'schema',
        schema: zodToJsonSchema(GPTResultSchema),
      },
    },
  };

  const time = Date.now();
  const response = await pnApiClient.request<OpenAI.Chat.ChatCompletion>({
    method: 'POST',
    url: 'v2/gpt/chat-completions', // TODO add extensive logging to this endpoint
    payload: { request },
  });
  console.log(`took ${(Date.now() - time) / 1e3}s`, response);

  assert(response.choices[0].message.content, 'No response content present.');
  const gptResult: GPTResultySchemaType = JSON.parse(
    response.choices[0].message.content
  );

  return gptResult;
}
