import { Box } from '@mui/material';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useRouter } from '@pn/core/operations/router';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import { Redirect, Route, Switch, matchPath } from 'react-router-dom';
import { MAIN_TRAY_WIDTH, WORKSPACE_DRAWER_WIDTH } from 'src/ui/Main';
import { FullHeightCircularLoader } from 'src/ui/components/FullHeightCircularLoader';
import { MainPanelHeader } from 'src/ui/main-panel/MainPanelHeader';
import { usePortals } from 'src/ui/main-panel/portals/PortalsProvider';
import { getMainPanelRoutes } from 'src/ui/main-panel/routes';
import { getMainPanelUri } from 'src/ui/main-panel/utils';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const MAIN_PANEL_MAX_WIDTH = 640;

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    top: 64,
    left: MAIN_TRAY_WIDTH,
    width: `calc(100% - ${MAIN_TRAY_WIDTH}px)`,
    maxWidth: MAIN_PANEL_MAX_WIDTH,
    height: 'calc(100% - 64px)',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).mainPanel,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 0,
      bottom: MAIN_TRAY_WIDTH,
      width: '100%',
      height: `calc(100% - 71px)`, // 100% - (height of horizontal tray)
      borderRight: 'none',
    },
    [`@media (max-width: ${
      MAIN_PANEL_MAX_WIDTH + MAIN_TRAY_WIDTH + WORKSPACE_DRAWER_WIDTH
    }px)`]: {
      maxWidth: `calc(100% - ${MAIN_TRAY_WIDTH}px - ${WORKSPACE_DRAWER_WIDTH}px)`,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'initial',
    },
    '@media print': {
      display: 'none',
    },
  },
  subContainer: {
    height: '100%',
    maxHeight: `calc(100% - 53px)`,
    padding: theme.spacing(2),
    overflow: 'auto',
  },
}));

type Props = {
  isMainTrayOpen: boolean;
};

export const MainPanel = ({ isMainTrayOpen }: Props) => {
  const { location } = useRouter();
  const { classes } = useStyles();

  const { workspaceItemSelected } = useWorkspaceStorage();
  const { portals } = usePortals();

  if (isNil(workspaceItemSelected)) return null;

  const mainPanelRoute = getMainPanelRoutes(
    workspaceItemSelected.dataType,
    workspaceItemSelected.mainPanelRoutes
  ).find((r) => !isNil(matchPath(location.pathname, { path: r.path })));
  if (!isMainTrayOpen || isNil(mainPanelRoute)) return null;

  const portalForCurrentPanel = portals.find(
    (portal) =>
      portal.item.dataType === workspaceItemSelected.dataType &&
      portal.route.title === mainPanelRoute?.title
  );
  if (!isNil(portalForCurrentPanel)) return null;

  return (
    <Box className={classes.container}>
      <MainPanelHeader item={workspaceItemSelected} route={mainPanelRoute} />
      <Panel item={workspaceItemSelected} />
    </Box>
  );
};

type PanelProps = {
  item: WorkspaceItem;
};

const Panel = ({ item }: PanelProps) => {
  const { classes } = useStyles();

  if (isNil(getDataItemSelected(item))) {
    return (
      <Box className={classes.subContainer}>
        <FullHeightCircularLoader />
      </Box>
    );
  }

  return (
    <Box className={classes.subContainer}>
      <Switch>
        {getMainPanelRoutes(item.dataType, item.mainPanelRoutes).map(
          (route) => (
            <Route
              key={route.path}
              path={route.path}
              render={() => route.getComponent({ item })}
            />
          )
        )}
        <Redirect
          to={getMainPanelUri({
            dataType: item.dataType,
            dataItemId: item.requestedDataItem.id,
          })}
        />
      </Switch>
    </Box>
  );
};
