import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import React from 'react';
import { useStyles } from './CustomTable.styles';
import { darken, lighten } from '@mui/material';

interface CustomTableProps extends DataGridProProps {
  areRowClicksEnabled: boolean;
  disableBorder?: boolean;
  className?: string;
  columns: any[];
  rows: any[];
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

export const CustomTable = ({
  areRowClicksEnabled = false,
  disableBorder = false,
  className,
  columns,
  rows,
  ...rest
}: CustomTableProps) => {
  const { classes, cx } = useStyles({ areRowClicksEnabled, disableBorder });

  const styledColumns = React.useMemo(
    () => columns.map((col) => ({ ...col, width: col.width ?? 150 })),
    [columns]
  );

  return (
    <DataGridPro
      columns={styledColumns}
      rows={rows}
      rowHeight={38}
      hideFooterSelectedRowCount
      className={cx(classes.root, className)}
      getRowClassName={(params) => `table-row-color-${params.row.color}`}
      sx={(theme) => ({
        '& .table-row-color-info': {
          backgroundColor: getBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode
          ),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(
              theme.palette.info.main,
              theme.palette.mode
            ),
          },
          '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
              theme.palette.info.main,
              theme.palette.mode
            ),
            '&:hover': {
              backgroundColor: getSelectedHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode
              ),
            },
          },
        },
      })}
      {...rest}
    />
  );
};
