import { ChartSeries } from '@pn/services/charts/types';
import { ChartData } from 'chart.js';
import { isNil } from 'lodash-es';
import React from 'react';

export function useApplyChartSeries(params: {
  chartRef: React.RefObject<any>;
  data: ChartData<'line', unknown[], unknown>;
  series: ChartSeries;
}) {
  React.useEffect(() => {
    if (isNil(params.chartRef.current)) return;

    function findIndexByKey(key: string) {
      return params.data.datasets.findIndex((dataset) => dataset.label === key);
    }

    Object.entries(params.series).forEach(([key, checked]) => {
      params.chartRef.current!.setDatasetVisibility(
        findIndexByKey(key),
        checked
      );
    });

    params.chartRef.current!.update();
  }, [params.chartRef, params.data, params.series]);
}
