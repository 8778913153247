import { UnitSystem } from '@pn/core/domain/types';
import { z } from 'zod';

const SortSchema = z.object({
  field: z.string().describe('Field from the mapping.'),
  direction: z.enum(['asc', 'desc']).describe('Sorting direction.'),
});

const FilterSchema = z.object({
  field: z.string(),
  unitSystem: z
    .nativeEnum(UnitSystem)
    .nullable()
    .describe('Required for metric/imperial units; null otherwise.'),
  operator: z.enum([
    'contains',
    'notContains',
    '>=',
    '<=',
    '==',
    'onOrAfter',
    'onOrBefore',
    'is',
    'isNil',
  ]),
  value: z
    .union([z.string(), z.number(), z.boolean(), z.null()])
    .describe('Unit values should always be > 0.001.'),
});

export const GPTResultSchema = z
  .object({
    unitConversions: z
      .string()
      .nullable()
      .describe(
        'Describe all unit conversions you performed; set to null if none.'
      ),
    insufficientSetup: z
      .boolean()
      .describe(
        "If true, the mapping was not sufficient to satisfy user's request."
      ),
    insufficientSetupReason: z
      .string()
      .nullable()
      .describe('Reason why the mapping was insufficient.'),
    sorts: z
      .array(SortSchema)
      .max(1)
      .describe(
        'Array of sorting configurations; can either be empty or contain one element.'
      ),
    filters: z.array(FilterSchema),
    filtersLinkOperator: z.enum(['and', 'or']),
    name: z.string().describe('Descriptive name of the filtered layer.'),
    color: z
      .string()
      .nullable()
      .describe('#HEX color of the layer; set to null if none was requested.'),
  })
  .strict();

export type GPTResultySchemaType = z.infer<typeof GPTResultSchema>;
