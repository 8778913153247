import { isGeoShape, type GeoShape } from '@pn/core/domain/geography';
import { generateColorMappingByProperty } from '@pn/core/domain/layer';
import { isDateString } from '@pn/core/domain/types';
import { isSIUnit, type SIUnit } from '@pn/core/domain/units';
import type {
  ColorPalette,
  GradientColorPalette,
} from '@pn/core/services/color/ports';
import { hasKey } from '@pn/core/utils/logic';
import { calculateBreaks } from '@pn/core/utils/statistics';
import { muiGradientColorPalettes } from '@pn/services/color/colorPalettes';
import { getStepColor } from '@pn/services/utils/color';
import assert from 'assert';
import {
  isBoolean,
  isNil,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from 'lodash-es';
import type { DataItem } from './dataItem';

type SharedMappingItem = {
  field: string;
  label: string;
  folder?: string;
  subFolder?: string;
  tooltip?: string;
  width?: number;
  isNotSortable?: true;
  isShownByDefault?: true;
  isNotRenderable?: true;
  source: {
    type: string; // original sourceType
  };

  /* GPT only */
  hint?: string;
};

type BooleanMappingItem = SharedMappingItem & {
  domainType: 'boolean';
  domainTypeAttributes?: never;
};
type NumberMappingItem = SharedMappingItem & {
  domainType: 'number';
  domainTypeAttributes?: never;
};
type StringMappingItem = SharedMappingItem & {
  domainType: 'string';
  domainTypeAttributes?: {
    options: string[];
  };
};
type ObjectMappingItem = SharedMappingItem & {
  domainType: 'object';
  domainTypeAttributes?: never;
};
type DateStringMappingItem = SharedMappingItem & {
  domainType: 'DateString';
  domainTypeAttributes: {
    format: string;
  };
};
type SIUnitMappingItem = SharedMappingItem & {
  domainType: 'SIUnit';
  domainTypeAttributes: {
    symbol: string;
  };
};
type GeoShapeMappingItem = SharedMappingItem & {
  domainType: 'GeoShape';
  domainTypeAttributes?: never;
};
type WKTMappingItem = SharedMappingItem & {
  domainType: 'WKT';
  domainTypeAttributes?: never;
};

export type MappingItem =
  | BooleanMappingItem
  | NumberMappingItem
  | StringMappingItem
  | ObjectMappingItem
  | DateStringMappingItem
  | SIUnitMappingItem
  | GeoShapeMappingItem
  | WKTMappingItem;

export function toMappingItem(
  params: SharedMappingItem & {
    domainType: MappingItem['domainType'];
    domainTypeAttributes?: MappingItem['domainTypeAttributes'];
  }
): MappingItem {
  const { domainType, domainTypeAttributes, ...rest } = params;

  switch (domainType) {
    case 'DateString':
      assert(
        !isNil(domainTypeAttributes) && 'format' in domainTypeAttributes,
        'format is required for DateString'
      );
      return {
        ...rest,
        domainType,
        domainTypeAttributes,
      };
    case 'SIUnit':
      assert(
        !isNil(domainTypeAttributes) && 'symbol' in domainTypeAttributes,
        'symbol is required for SIUnit'
      );
      return {
        ...rest,
        domainType,
        domainTypeAttributes,
      };
    case 'string':
      if (domainTypeAttributes) assert('options' in domainTypeAttributes);
      return {
        ...rest,
        domainType,
        domainTypeAttributes,
      };
    default:
      assert(
        isNil(domainTypeAttributes),
        'domainTypeAttributes is not allowed'
      );
      return {
        ...rest,
        domainType,
        domainTypeAttributes,
      };
  }
}

export function isDropdownAttributes(
  attributes: unknown
): attributes is { options: string[] } {
  return isObject(attributes) && hasKey(attributes, 'options');
}

type BooleanValue = {
  value: boolean | undefined;
  domainType: BooleanMappingItem['domainType'];
  mappingItem: BooleanMappingItem;
};
type NumberValue = {
  value: number | undefined;
  domainType: NumberMappingItem['domainType'];
  mappingItem: NumberMappingItem;
};
type StringValue = {
  value: string | undefined;
  domainType: StringMappingItem['domainType'];
  mappingItem: StringMappingItem;
};
type DateStringValue = {
  value: string | undefined;
  domainType: DateStringMappingItem['domainType'];
  mappingItem: DateStringMappingItem;
};
type WKTValue = {
  value: GeoShape | undefined;
  domainType: WKTMappingItem['domainType'];
  mappingItem: WKTMappingItem;
};
type SIUnitValue = {
  value: SIUnit | undefined;
  domainType: SIUnitMappingItem['domainType'];
  mappingItem: SIUnitMappingItem;
};
type GeoShapeValue = {
  value: GeoShape | undefined;
  domainType: GeoShapeMappingItem['domainType'];
  mappingItem: GeoShapeMappingItem;
};
type ObjectValue = {
  value: object | undefined;
  domainType: ObjectMappingItem['domainType'];
  mappingItem: ObjectMappingItem;
};
export type DomainValue =
  | BooleanValue
  | NumberValue
  | StringValue
  | DateStringValue
  | WKTValue
  | SIUnitValue
  | GeoShapeValue
  | ObjectValue;

export function toDomainValue(
  value: DomainValue['value'],
  mappingItem: MappingItem
): DomainValue {
  switch (mappingItem.domainType) {
    case 'boolean':
      assert(
        isBoolean(value) || isUndefined(value),
        `Expected boolean, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'number':
      assert(
        isNumber(value) || isUndefined(value),
        `Expected number, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'string':
      assert(
        isString(value) || isUndefined(value),
        `Expected string, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'object':
      assert(
        isObject(value) || isUndefined(value),
        `Expected object, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'DateString':
      assert(
        isDateString(value) || isUndefined(value),
        `Expected DateString, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'SIUnit':
      assert(
        isSIUnit(value) || isUndefined(value),
        `Expected SIUnit, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'GeoShape':
      assert(
        isGeoShape(value) || isUndefined(value),
        `Expected GeoShape, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    case 'WKT':
      assert(
        isGeoShape(value) || isUndefined(value),
        `Expected GeoShape, got ${value}`
      );
      return { value, domainType: mappingItem.domainType, mappingItem };
    default:
      return mappingItem satisfies never;
  }
}

export function pickMappingItemsFromFields(
  mapping: MappingItem[],
  fields: string[]
): MappingItem[] {
  const existingMappingItems = [];

  for (const field of fields) {
    const mappingItem = mapping.find((m) => m.field === field);
    if (!isNil(mappingItem)) {
      existingMappingItems.push(mappingItem);
    } else {
      console.warn(`Skipping [${field}] field: not present in mapping`);
    }
  }

  return existingMappingItems;
}

type ColorGeneratorFn = (
  dataItems: DataItem[],
  options?: {
    staggeredColors?: Record<number, string>;
    gradientColorPalette?: GradientColorPalette;
    desiredBinCount?: number;
  }
) => string | unknown[];
type ColorGenerator = (
  mappingItem: MappingItem,
  colorPalette: ColorPalette
) => ColorGeneratorFn;

const generateBooleanColor: ColorGenerator =
  (mappingItem, colorPalette) => (_dataItems, options) => {
    const { staggeredColors = {} } = options ?? {};

    const color = [
      'match',
      ['to-string', ['get', mappingItem.field]],
      'true',
      staggeredColors[0] ?? colorPalette.defaultColor,
      'false',
      staggeredColors[1] ?? colorPalette.defaultColor,
      colorPalette.defaultColor,
    ];

    return color;
  };

function accessNumericField(item: DataItem, field: string): number | undefined {
  const v = item[field];
  if (isNumber(v) || isUndefined(v)) return v;
  if (isSIUnit(v)) return v.value;
  if (isDateString(v)) return parseInt(v);
  throw new Error(`Cannot access a non-numeric field [${field}]`);
}

export const generateStepColor: ColorGenerator =
  (mappingItem, colorPalette) => (dataItems, options) => {
    const {
      gradientColorPalette = muiGradientColorPalettes[0],
      desiredBinCount = 5,
    } = options ?? {};

    // 1. Extract numeric values and calculate min/max
    let min = Infinity;
    let max = -Infinity;

    const values: number[] = dataItems
      .map((item) => {
        const value = accessNumericField(item, mappingItem.field);

        if (isNil(value)) return undefined;

        if (value < min) min = value;
        if (value > max) max = value;

        return value;
      })
      .filter((v): v is NonNullable<typeof v> => !isNil(v));

    // 2. Determine breaks
    const breaks: number[] = calculateBreaks.natural(values, desiredBinCount);

    // 3. Generate colors for these breaks
    const colors: string[] = breaks.map((_, index) => {
      return getStepColor({
        index,
        total: breaks.length,
        min: gradientColorPalette.colorLower,
        max: gradientColorPalette.colorUpper,
      });
    });

    // 4. Construct step expression
    const colorExpression = [
      'step',
      mappingItem.domainType === 'DateString'
        ? ['to-number', ['get', mappingItem.field]]
        : ['get', mappingItem.field],
      colorPalette.defaultColor, // no value
      ...colors.flatMap((color, i) => [breaks[i], color]),
    ];

    return colorExpression;
  };

// TODO merge with generateStepColor
export const generateStepStyle = (
  mappingItem: MappingItem,
  dataItems: DataItem[],
  options: {
    defaultValue?: number;
    minValue: number;
    maxValue: number;
    desiredBinCount?: number;
  }
) => {
  const {
    defaultValue = options.minValue,
    minValue,
    maxValue,
    desiredBinCount = 5,
  } = options;

  // 1. Extract numeric values and calculate min/max
  let min = Infinity;
  let max = -Infinity;

  const values: number[] = dataItems
    .map((item) => {
      const value = accessNumericField(item, mappingItem.field);

      if (isNil(value)) return undefined;

      if (value < min) min = value;
      if (value > max) max = value;

      return value;
    })
    .filter((v): v is NonNullable<typeof v> => !isNil(v));

  // 2. Determine breaks
  const breaks: number[] = calculateBreaks.natural(values, desiredBinCount);

  /**
   * Return a fake expression to provide the max value to getDefaultRadiusRange.
   */
  if (breaks.length === 1)
    return [
      'step',
      mappingItem.domainType === 'DateString'
        ? ['to-number', ['get', mappingItem.field]]
        : ['get', mappingItem.field],
      defaultValue,
      breaks[0] + 1, // won't match anything
      maxValue,
    ];

  // 3. Generate values for these breaks
  const breakValues = breaks.map((_, index) => {
    return minValue + ((maxValue - minValue) / (breaks.length - 1)) * index;
  });

  // 4. Construct step expression
  const stepExpression = [
    'step',
    mappingItem.domainType === 'DateString'
      ? ['to-number', ['get', mappingItem.field]]
      : ['get', mappingItem.field],
    defaultValue,
    ...breakValues.flatMap((breakValue, i) => [breaks[i], breakValue]),
  ];

  return stepExpression;
};

const generateRankedByAttributeColor: ColorGenerator =
  (mappingItem, colorPalette) => (dataItems, options) => {
    const { staggeredColors = {} } = options ?? {};

    const record: Record<string, number> = {};

    for (const item of dataItems) {
      const value =
        mappingItem.domainType === 'DateString'
          ? (item[mappingItem.field] ?? 'N/A').toString().slice(0, 4)
          : (item[mappingItem.field] ?? 'N/A').toString();

      record[value] = (record[value] || 0) + 1;
    }

    delete record['N/A']; // QUESTION

    const orderedValues = Object.entries(record)
      .sort((a, b) =>
        mappingItem.domainType === 'DateString'
          ? parseInt(b[0]) - parseInt(a[0])
          : b[1] - a[1]
      )
      .map(([key]) => key);

    const mapping = orderedValues.map((value, index) => ({
      value,
      color: staggeredColors[index] ?? colorPalette.defaultColor,
    }));

    /**
     * `coalesce` deals with undefined values.
     */
    const colorExpression = generateColorMappingByProperty(
      mapping,
      mappingItem.domainType === 'DateString'
        ? ['slice', ['coalesce', ['get', mappingItem.field], ''], 0, 4]
        : ['coalesce', ['get', mappingItem.field], ''],
      colorPalette.defaultColor
    );

    return colorExpression;
  };

export const supportedDomainTypes = [
  'boolean',
  'number',
  'string',
  'DateString',
  'SIUnit',
];
export const mappingItemColorGenerator = (
  mappingItem: MappingItem,
  colorPalette: ColorPalette
): ColorGeneratorFn => {
  switch (mappingItem.domainType) {
    case 'boolean':
      return generateBooleanColor(mappingItem, colorPalette);
    case 'number':
    case 'SIUnit':
      return generateStepColor(mappingItem, colorPalette);
    case 'string':
    case 'DateString':
      return generateRankedByAttributeColor(mappingItem, colorPalette);
    default:
      return () => colorPalette.defaultColor;
  }
};
