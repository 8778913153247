import { DrawingProvider } from '@pn/services/drawing';
import { StackedNotificationProvider } from '@pn/services/notifications/StackedNotificationProvider';
import { ConfirmationDialogStateProvider } from '@pn/ui/context-components/ConfirmationDialog';
import { ExternalPanelStateProvider } from '@pn/ui/context-components/ExternalPanel';
import { WorkspaceItemPanelProvider } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import { ExtraDataProvider } from 'src/ui/main-panel/components/dense-components/ExtraDataProvider';
import { PortalsProvider } from 'src/ui/main-panel/portals/PortalsProvider';
import { LibraryProvider } from 'src/ui/workspace/library/LibraryProvider';
import { ProjectStateProvider } from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { ShareDialogProvider } from 'src/ui/workspace/share/ShareDialogProvider';
import { UploadDialogProvider } from 'src/ui/workspace/upload-dialog/UploadDialogProvider';

type Props = {
  children: React.ReactNode;
};
type ReactProvider = React.ComponentType<Props>;

export const AppContextProviders = ({ children }: Props) => {
  const providers: ReactProvider[] = [
    StackedNotificationProvider,
    ConfirmationDialogStateProvider,
    WorkspaceItemPanelProvider,
    LibraryProvider,
    ProjectStateProvider,
    ShareDialogProvider,
    UploadDialogProvider,
    DrawingProvider,
    ExternalPanelStateProvider,
    ExtraDataProvider,
    PortalsProvider,
  ];

  return composeProviders(providers, children);
};

function composeProviders(
  providers: ReactProvider[],
  children: React.ReactNode
): React.ReactNode {
  return providers.reduceRight(
    (kids, Provider) => <Provider>{kids}</Provider>,
    children
  );
}
