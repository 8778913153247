import type { PostgresMappingItem } from '@pn/services/api/data/types';

const wellLicenceFields: PostgresMappingItem[] = [
  {
    field: 'id',
    label: 'Licence Number',
    width: 80,
    sourceType: 'int',
    isShownByDefault: true,
  },
  {
    field: 'licensee',
    label: 'Licensee',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'confidential_release_date',
    label: 'Release Date',
    isShownByDefault: true,
    sourceType: 'text',
    width: 98,
  },
  // {
  //   field: 'recent_oil_and_condensate_bbl_per_day',
  //   label: 'Oil + Condensate',
  //   sourceType: 'float',
  //   isShownByDefault: true,
  //   domainType: 'SIUnit',
  //   domainTypeAttributes: {
  //     symbol: 'bbl/d',
  //   },
  // },
  {
    field: 'recent_oil_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
    width: 80,
  },
  {
    field: 'recent_condensation_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
    width: 80,
  },
  {
    field: 'recent_gas_mcf_per_day',
    label: 'Gas',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'mcf/d',
    },
    width: 80,
  },
  {
    field: 'recent_ngl_bbl_per_day',
    label: 'NGL',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
    width: 80,
  },
  {
    field: 'formations',
    label: 'Formations',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'field_name',
    label: 'Field',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'producing_hours',
    label: 'Producing Hours',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'on_prod_date',
    label: 'On Prod',
    sourceType: 'date',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'cumulative_oil_volume_bbl',
    label: 'Oil Cumulative',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl',
    },
  },
  {
    field: 'surface_uwi',
    label: 'Surface Location',
    isShownByDefault: true,
    width: 140,
    sourceType: 'text',
  },
  {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    isShownByDefault: true,
    width: 60,
  },
];

export const confidentialWellsLicensesMapping: PostgresMappingItem[] = [
  ...wellLicenceFields,
];

export const declassifiedWellsLicensesMapping: PostgresMappingItem[] = [
  ...wellLicenceFields,
];
