import { useBufferZones } from '@pn/core/operations/mapInteractions/bufferZones';
import { isEmpty, sortBy } from 'lodash-es';

export function useBufferZonesCommand() {
  const { generateBufferZones } = useBufferZones();

  return async (...args: string[]) => {
    if (isEmpty(args)) return 'ERROR No buffer distance(s) provided.';

    const distances = sortBy(args.map(Number));
    if (distances.some(isNaN) || distances.some((d) => d === 0))
      return 'ERROR Invalid distance(s).';

    try {
      await generateBufferZones({ inputDistances: distances });
    } catch (error) {
      console.error(error);
      if (error instanceof Error) return `ERROR ${error.message}.`;
      return 'ERROR Buffer zones generation failed.';
    }

    return 'Buffer zones generated.';
  };
}
