import type { CanvasIcon } from '@pn/core/domain/drawing';

export const canvasIcons: CanvasIcon[] = [
  {
    id: 'circle',
    pathData:
      'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2',
    viewBox: '0 0 24 24',
  },
  {
    id: 'circle_outlined',
    pathData:
      'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8',
    viewBox: '0 0 24 24',
  },
  {
    id: 'square',
    pathData: 'M3 3h18v18H3z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'square_outlined',
    pathData: 'M3 3v18h18V3zm16 16H5V5h14z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'triangle',
    pathData: 'M2,20 L22,20 L12,4 Z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'change_history',
    pathData: 'M12 7.77 18.39 18H5.61zM12 4 2 20h20z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'star',
    pathData:
      'M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'push_pin',
    pathData:
      'M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3',
    viewBox: '0 0 24 24',
  },
  {
    id: 'place',
    pathData:
      'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5',
    viewBox: '0 0 24 24',
  },
  {
    id: 'home',
    pathData: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'terrain',
    pathData: 'm14 6-3.75 5 2.85 3.8-1.6 1.2C9.81 13.75 7 10 7 10l-6 8h22z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'park',
    pathData: 'M17 12h2L12 2 5.05 12H7l-3.9 6h6.92v4h3.96v-4H21z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'person',
    pathData:
      'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4',
    viewBox: '0 0 24 24',
  },
  {
    id: 'local_shipping',
    pathData:
      'M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m13.5-9 1.96 2.5H17V9.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5',
    viewBox: '0 0 24 24',
  },
  // {
  //   id: 'settings',
  //   pathData:
  //     'M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6',
  //   viewBox: '0 0 24 24',
  // },
  {
    id: 'lock',
    pathData:
      'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2m-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'lock_open',
    pathData:
      'M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2m0 12H6V10h12z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'gas_meter',
    pathData:
      'M16 4h-1V2h-2v2h-2V2H9v2H8C5.79 4 4 5.79 4 8v10c0 2.21 1.79 4 4 4h8c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4m-4 14c-1.38 0-2.5-1.1-2.5-2.46 0-1.09.43-1.39 2.5-3.79 2.05 2.38 2.5 2.7 2.5 3.79C14.5 16.9 13.38 18 12 18m4-8H8V8h8z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'construction',
    pathData:
      'm13.7826 15.1719 2.1213-2.1213 5.9963 5.9962-2.1213 2.1213zM17.5 10c1.93 0 3.5-1.57 3.5-3.5 0-.58-.16-1.12-.41-1.6l-2.7 2.7-1.49-1.49 2.7-2.7c-.48-.25-1.02-.41-1.6-.41C15.57 3 14 4.57 14 6.5c0 .41.08.8.21 1.16l-1.85 1.85-1.78-1.78.71-.71-1.41-1.41L12 3.49c-1.17-1.17-3.07-1.17-4.24 0L4.22 7.03l1.41 1.41H2.81l-.71.71 3.54 3.54.71-.71V9.15l1.41 1.41.71-.71 1.78 1.78-7.41 7.41 2.12 2.12L16.34 9.79c.36.13.75.21 1.16.21',
    viewBox: '0 0 24 24',
  },
  // {
  //   id: 'fa_pipe_valve',
  //   pathData:
  //     'm282.67 96.002c0-14.75-11.92-26.667-26.67-26.667s-26.66 11.917-26.66 26.667v9.998l-80-9.998c-14.76 0-26.67 11.918-26.67 26.668s11.91 26.67 26.67 26.67l106.66-13.34 106.67 13.34c14.75 0 26.67-11.92 26.67-26.67s-11.92-26.668-26.67-26.668l-80 9.998zm0 70.158-26.67-3.33-26.66 3.33v36.5h-106.67v213.34h266.67v-213.33h-106.67zm-213.34 9.84c-14.75 0-26.667 11.91-26.667 26.66v213.34c0 14.75 11.917 26.66 26.667 26.66s26.666-11.91 26.666-26.66v-213.33c0-14.75-11.916-26.66-26.666-26.67zm400 26.66c0-14.75-11.92-26.66-26.67-26.66s-26.67 11.91-26.67 26.66v213.34c0 14.75 11.92 26.66 26.67 26.66s26.67-11.91 26.67-26.66z',
  //   viewBox: '0 0 512 512',
  // },
  // {
  //   id: 'power',
  //   pathData:
  //     'M16.01 7 16 3h-2v4h-4V3H8v4h-.01C7 6.99 6 7.99 6 8.99v5.49L9.5 18v3h5v-3l3.5-3.51v-5.5c0-1-1-2-1.99-1.99',
  //   viewBox: '0 0 24 24',
  // },
  {
    id: 'bolt',
    pathData:
      'M11 21h-1l1-7H7.5c-.58 0-.57-.32-.38-.66s.05-.08.07-.12C8.48 10.94 10.42 7.54 13 3h1l-1 7h3.5c.49 0 .56.33.47.51l-.07.15C12.96 17.55 11 21 11 21',
    viewBox: '0 0 24 24',
  },
  {
    id: 'water_drop',
    pathData:
      'M12 2c-5.33 4.55-8 8.48-8 11.8 0 4.98 3.8 8.2 8 8.2s8-3.22 8-8.2c0-3.32-2.67-7.25-8-11.8M7.83 14c.37 0 .67.26.74.62.41 2.22 2.28 2.98 3.64 2.87.43-.02.79.32.79.75 0 .4-.32.73-.72.75-2.13.13-4.62-1.09-5.19-4.12-.08-.45.28-.87.74-.87',
    viewBox: '0 0 24 24',
  },
  {
    id: 'local_fire_department',
    pathData:
      'M12 12.9L9.87 14.99c-.56.56-.87 1.29-.87 2.07C9 18.68 10.35 20 12 20s3-1.32 3-2.94c0-.78-.31-1.52-.87-2.07L12 12.9ZM16 6l-.44.55C14.38 8.02 12 7.19 12 5.3V2S4 6 4 13c0 2.92 1.56 5.47 3.89 6.86-.56-.79-.89-1.76-.89-2.8 0-1.32.52-2.56 1.47-3.5L12 10.1l3.53 3.47c.95.93 1.47 2.17 1.47 3.5 0 1.02-.31 1.96-.85 2.75 1.89-1.15 3.29-3.06 3.71-5.3.66-3.55-1.07-6.9-3.86-8.52Z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'fa_explosion',
    pathData:
      'm464.33 52.101c5.59-8.916 17.09-12.083 26.42-7.083s13.17 16.25 8.83 25.833l-114.25 254c1.84 1.92 3.59 3.92 5.25 5.92l81-45.59c8.75-4.91 19.67-2.58 25.75 5.34 6.09 7.91 5.25 19.16-1.83 26.25l-72.5 72.5h-59.5c-11-31.09-40.58-53.34-75.42-53.34-34.83 0-64.5 22.25-75.41 53.34h-66.34l-63.08-43.5c-8.083-5.59-10.917-16.34-6.583-25.25 4.333-8.92 14.5-13.25 23.913-10.34l81 25.34c2.5-3.25 5.09-6.42 7.84-9.42l-51.92-86.5c-5.08-8.42-3.25-19.25 4.25-25.58 7.5-6.34 18.5-6.25 25.92 0.1l85.33 73.23c1.25-0.33 2.5-0.67 3.75-0.92l11.33-118.91c1-10.25 9.59-18.09 19.92-18.09s18.92 7.84 19.92 18.09l11.25 118.25zm-363 363.92h400c14.75 0 26.67 11.91 26.67 26.66s-11.92 26.67-26.67 26.67h-426.66c-14.75 0-26.667-11.92-26.667-26.67s11.917-26.66 26.667-26.66zm186.67-373.34c11.08 0 20 8.916 20 20v40.005c0 11.08-8.92 20-20 20s-20-8.92-20-20v-40.005c0-11.084 8.92-20 20-20z',
    viewBox: '0 0 576 512',
  },
  {
    id: 'fa_skull_crossbones_sharp',
    pathData:
      'm344.14 149.45c0 37.05-21.19 69.67-53.39 88.77v44.72h-133.49v-44.72c-32.21-19.19-53.4-51.81-53.4-88.77 0-58.979 53.82-106.78 120.14-106.78 66.33 0 120.14 47.806 120.14 106.78zm-166.86 40.05a26.698 26.698 0 1 0 0-53.39 26.698 26.698 0 1 0 0 53.39zm120.13-26.69a26.698 26.698 0 1 0-53.38 0 26.698 26.698 0 1 0 53.38 0zm-246.36 84.59 24.196 11.35 148.75 70.09 148.85-70.09 24.19-11.34 22.78 48.31-24.19 11.35-108.97 51.3 108.88 51.23 24.21 11.35-22.79 48.31-24.2-11.35-148.76-70-148.83 70.08-24.195 11.35-22.777-48.32 24.195-11.34 108.88-51.22-108.8-51.32-24.196-11.35 22.777-48.31z',
    viewBox: '0 0 448 512',
  },
  {
    id: 'verified',
    pathData:
      'm23 12-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69zm-12.91 4.72-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'help',
    pathData:
      'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m1 17h-2v-2h2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25',
    viewBox: '0 0 24 24',
  },
  {
    id: 'warning',
    pathData: 'M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'check',
    pathData: 'M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z',
    viewBox: '0 0 24 24',
  },
  {
    id: 'block',
    pathData:
      'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2M4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12m8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8',
    viewBox: '0 0 24 24',
  },
  // {
  //   id: 'fa_biohazard',
  //   pathData:
  //     'm192.34 42.644c-1.5 0-2.91 0.583-4 1.667-24.91 25.25-40.33 59.999-40.33 98.329 0 21.84 5 42.42 13.83 60.84-18.33 2-36.49 7.58-53.49 17.08-28.754 16.08-49.255 40.75-60.005 68.75-0.584 1.42-0.417 3.09 0.416 4.34 1.834 3.08 6.167 3.58 8.834 1.08 43.915-40.17 122.08-47.83 161.26 17.92 39.16 65.75-6.75 127.58-64.67 144.33-3.5 1-5.25 4.92-3.42 8 0.83 1.34 2.17 2.25 3.75 2.5 30.42 4.92 62.67 0.1 91.42-16 17-9.5 31.17-22.08 42.08-36.5 10.92 14.42 25.09 27 42.09 36.5 28.75 16.09 61.08 21 91.42 16 1.58-0.25 2.91-1.16 3.75-2.5 1.83-3.08 0.1-7-3.42-8-57.92-16.75-103.84-78.5-64.67-144.33 39.17-65.84 117.25-58.17 161.26-17.84 2.66 2.42 7 1.92 8.83-1.08 0.83-1.33 0.92-2.92 0.42-4.33-10.75-28-31.25-52.67-60.09-68.76-17-9.5-35.17-15.08-53.5-17.08 8.92-18.5 13.92-39.08 13.92-60.92 0-38.33-15.42-73.079-40.34-98.329-1.08-1.084-2.5-1.667-4-1.667-4.16 0-7 4.333-5.58 8.25 21.33 58.836-8.75 138.42-90.09 138.42-81.33 0-111.42-79.58-90.08-138.42 1.41-3.917-1.34-8.25-5.59-8.25zm55.67 226.67a40.001 40.001 0 1 1 80 0 40.001 40.001 0 1 1-80 0zm-48.58 121.34c5.25-9.83 8.16-20.92 7.16-33.17-16.25-15-28.33-34.5-34.33-56.5-10.42-6.75-21.83-9.83-33.33-10.33-7.5-0.34-15.08 0.5-22.58 2.25 6.5 47.58 32.24 89 69.08 116.17 5.66-5.59 10.5-11.75 14-18.42zm88.58-294.67c-24 0-46.91 4.911-67.66 13.751 1.66 6.92 4.16 13.5 7.5 19.58 5.66 10.34 13.91 19.25 25.08 25.25 11.08-3.42 22.92-5.25 35.17-5.25s24 1.83 35.16 5.25c11.17-6 19.42-14.91 25.09-25.25 3.33-6.08 5.83-12.66 7.5-19.58-20.92-8.84-43.84-13.751-67.84-13.751zm115.76 205c-6 22-18.09 41.42-34.34 56.5-1 12.25 1.84 23.42 7.17 33.17 3.58 6.67 8.33 12.83 14 18.42 36.92-27.17 62.67-68.59 69.08-116.17-7.5-1.84-15.08-2.59-22.58-2.25-11.5 0.5-22.92 3.66-33.33 10.33z',
  //   viewBox: '0 0 576 512',
  // },
  // {
  //   id: 'fa_circle_radiation',
  //   pathData:
  //     'm256 96.001a160 160 0 1 1 0 319.99 160 160 0 1 1 0-319.99zm0 373.33a213.34 213.33 0 1 0 0-426.66 213.34 213.33 0 1 0 0 426.66zm-46.66-213.34c0-17.25 9.41-32.33 23.33-40.41l-30-51.91c-7.33-12.75-23.92-17.33-35-7.5-21.33 18.83-36.58 44.42-42.42 73.41-2.83 14.33 9.34 26.41 24.09 26.41zm23.33 40.42-30 52c-7.33 12.75-3 29.33 10.92 34 13.33 4.5 27.58 6.92 42.41 6.92 14.84 0 29.09-2.42 42.42-6.92 13.92-4.67 18.25-21.25 10.92-34l-30-52c-6.84 4-14.84 6.25-23.34 6.25s-16.5-2.25-23.33-6.25zm70-40.42h60c14.75 0 26.92-12.08 24-26.5-5.83-28.99-21.08-54.57-42.42-73.4-11-9.75-27.58-5.25-35 7.5l-30 51.9c13.92 8.09 23.34 23.17 23.34 40.42zm-46.67 26.67a26.667 26.666 0 1 0 0-53.33 26.667 26.666 0 1 0 0 53.33z',
  //   viewBox: '0 0 512 512',
  // },
];
