import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isArray, isNumber, isObject, isString } from 'lodash-es';

export type GeoJSONCoordinates = GeoJSON.Position | GeoJSONCoordinates[];

export function isGeoJSONGeometry(arg: unknown): arg is GeoJSON.Geometry {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'type', isString) &&
    [
      'Point',
      'MultiPoint',
      'LineString',
      'MultiLineString',
      'Polygon',
      'MultiPolygon',
      'GeometryCollection',
    ].includes(arg.type) &&
    hasKeyWithType(arg, 'coordinates', isArray)
  );
}

export function isGeoJSONFeatureCollection(
  arg: unknown
): arg is GeoJSON.FeatureCollection {
  return (
    isObject(arg) &&
    hasKey(arg, 'type') &&
    arg.type === 'FeatureCollection' &&
    hasKeyWithType(arg, 'features', isArray)
  );
}

const isLongitude = (num: unknown): boolean =>
  isNumber(num) && isFinite(num) && Math.abs(num) <= 180;
const isLatitude = (num: unknown): boolean =>
  isNumber(num) && isFinite(num) && Math.abs(num) <= 90;

export function isValidLonLat(param: unknown): param is [number, number] {
  if (!isArray(param) || param.length !== 2) {
    return false;
  }

  return isLongitude(param[0]) && isLatitude(param[1]);
}

/**
 * @returns true if parameter is of type [lon, lat] or [lon, lat, alt]
 */
export function isGeoJSONPosition(param: unknown): param is GeoJSON.Position {
  if (!isArray(param) || param.length < 2 || param.length > 3) {
    return false;
  }

  const isLon = isLongitude(param[0]);
  const isLat = isLatitude(param[1]);

  // If the first coordinate is a valid latitude but not a valid longitude
  // AND the second coordinate is a valid longitude but not a valid latitude,
  // then warn that it's possibly [lat, lon] format.
  if (!isLon && !isLat && isLatitude(param[0]) && isLongitude(param[1])) {
    console.error(
      'Expected coordinates in [lon, lat] format but received potential [lat, lon] format: ' +
        [param[0], param[1]].join(', ')
    );
  }

  return isLon && isLat;
}
