import { useStackProjects } from '@pn/core/operations/stackdx/stackProjects';
import {
  projectsActions,
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import {
  getStackProjects,
  mapStackProjects,
} from '@pn/services/api/stackdx/apiStackProjectProvider';
import type { StackProject } from '@pn/services/api/stackdx/types';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

export function useAutoGetStackProjects() {
  const { stackToken } = useCurrentUserStorage();
  const { stackSourceItems } = useStackProjects();

  const shouldProcessProjects = React.useRef(false);
  const [stackProjectsToProcess, setStackProjectsToProcess] = React.useState<
    StackProject[]
  >([]);

  /**
   * Start fetching Stack projects as soon as the token is available.
   */
  React.useEffect(() => {
    if (isNil(stackToken)) return;

    let isMounted = true;

    (async () => {
      workspaceActions().updateIsFetchingStack(true);

      const projects = await getStackProjects();

      if (isMounted) {
        setStackProjectsToProcess(projects);
        shouldProcessProjects.current = true;
      }
    })();

    return () => {
      isMounted = false;
      workspaceActions().removeStackOriginatedItems();
      projectsActions().removeStackProjects();
    };
  }, [stackToken]);

  /**
   * Map and store Stack projects once their source layers become available.
   * Stack projects map to both PN Projects and PN Workspace Items.
   */
  React.useEffect(() => {
    if (
      !shouldProcessProjects.current ||
      isEmpty(stackProjectsToProcess) ||
      isEmpty(stackSourceItems)
    ) {
      return;
    }

    shouldProcessProjects.current = false;

    const { workspaceItems, projects } = mapStackProjects({
      stackProjects: stackProjectsToProcess,
      sourceWorkspaceItems: [...stackSourceItems, ...pnWorkspaceItems],
    });

    workspaceActions().updateIsFetchingStack(false);
    workspaceActions().add(
      workspaceItems,
      false // do not upsert
    );

    projectsActions().add(
      projects,
      false // do not upsert
    );
  }, [stackProjectsToProcess, stackSourceItems]);
}
