import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { useAppStorage, useWorkspaceStorage } from '@pn/core/storage';
import { downloadDataItems } from '@pn/services/exports/data/downloadDataItems';
import download from 'downloadjs';
import { isEmpty, isNil } from 'lodash-es';

export function useExportGeoJSONCommand() {
  const { unitSystem } = useAppStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { fields } = useTableFields(workspaceItemSelected?.dataType ?? '');

  return () => {
    if (isNil(workspaceItemSelected)) return 'Error: no layer selected.';

    if (workspaceItemSelected.itemType === 'annotation') {
      const geojsonString = JSON.stringify(
        (
          workspaceItemSelected.map.layers[0]
            .source as mapboxgl.GeoJSONSourceRaw
        ).data
      );
      download(geojsonString, 'annotation.geojson', 'application/json');
      return 'Annotation GeoJSON downloaded.';
    }

    const mapDataItems = getMapDataItems(workspaceItemSelected);
    if (isEmpty(mapDataItems)) return 'Error: no data items to export.';

    downloadDataItems({
      data: getMapDataItems(workspaceItemSelected),
      fields,
      mapping: workspaceItemSelected.mapping,
      unitSystem,
    }).geojson({
      fileName: (workspaceItemSelected.name || 'my_export') + '.geojson',
    });

    return 'GeoJSON downloaded.';
  };
}
