import { Box, Typography, useTheme } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { useDrawing } from '@pn/services/drawing';
import { OpacityInputWithToggles } from '@pn/ui/inputs/OpacityInputWithToggles';
import Color from 'color';
import { isNil, round } from 'lodash-es';
import { deriveTextColor } from 'src/ui/drawing/controls/TextColor';
import { deriveFillColor } from './FillColor';
import { deriveStrokeColor } from './StrokeColor';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const Opacity = ({ featureLive }: Props) => {
  const theme = useTheme();

  const {
    drawingMode,
    strokeColor,
    fillColor,
    textColor,
    opacity,
    setOpacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const value =
    featureLive && 'opacity' in featureLive
      ? Math.round(featureLive.opacity * 100)
      : drawingMode !== 'select'
        ? Math.round(opacity * 100)
        : undefined;

  const handleChange = (rawOpacity: number) => {
    const opacity = round(rawOpacity / 100, 2);

    setOpacity(opacity);
    drawingState.opacity = opacity;

    if (featureLive) {
      drawingState.featuresSelected[featureLive.id].opacity = opacity;
      drawingState.features[featureLive.id].opacity = opacity;
      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(value)) return null;

  const opacityColor =
    deriveStrokeColor(featureLive, drawingMode, strokeColor) ??
    deriveFillColor(featureLive, drawingMode, fillColor) ??
    deriveTextColor(featureLive, drawingMode, textColor) ??
    (theme.palette.mode === 'light' ? '#000' : '#fff');

  const opacityStroke =
    Color(opacityColor).contrast(Color(theme.palette.background.paper)) < 1.25
      ? theme.borderColor
      : undefined;

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Opacity
      </Typography>
      <OpacityInputWithToggles
        opacityColor={opacityColor}
        opacityStroke={opacityStroke}
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};
