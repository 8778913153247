import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  IconButton,
  type PaletteMode,
  Slide,
  type SlideProps,
  Snackbar,
  type SnackbarCloseReason,
  snackbarContentClasses,
  useTheme,
} from '@mui/material';
import { orange, red } from '@mui/material/colors';
import type { Notification } from '@pn/core/services/notifications/ports';
import { isNil } from 'lodash-es';
import React from 'react';
import {
  Provider,
  type TypedUseSelectorHook,
  createSelectorHook,
} from 'react-redux';
import {
  type State,
  gracefullyRemoveNotification,
  notificationStore,
  removeNotification,
} from './stackedNotificationStore';

const notificationStoreContext = React.createContext<any>(undefined);

type Props = {
  children: React.ReactNode;
};

export const StackedNotificationProvider = ({ children }: Props) => {
  return (
    <>
      <Provider store={notificationStore} context={notificationStoreContext}>
        <SnackbarManager maxStack={3} autoHideDuration={3000} />
      </Provider>
      {children}
    </>
  );
};

const useNotificationSelector: TypedUseSelectorHook<State> = createSelectorHook(
  notificationStoreContext
);

type SnackbarManagerProps = {
  maxStack: number;
  autoHideDuration: number;
};

const SnackbarManager = ({
  maxStack,
  autoHideDuration,
}: SnackbarManagerProps) => {
  const theme = useTheme();

  const notifications = useNotificationSelector((state) => state.notifications);

  React.useEffect(() => {
    if (notifications.length > maxStack) {
      const firstToBeRemoved = notifications.filter(
        (notification) => !notification.isPersistent
      )[0];
      if (!isNil(firstToBeRemoved)) {
        removeNotification(firstToBeRemoved.id);
      }
    }
  }, [maxStack, notifications]);

  const handleClose = (
    notification: Notification,
    _reason: SnackbarCloseReason
  ) => {
    if (notification.isPersistent) return;

    gracefullyRemoveNotification(notification.id);
  };

  return (
    <>
      {notifications.map((notification, index) => (
        <Snackbar
          key={notification.id}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={notification.isOpen}
          autoHideDuration={
            notification.isPersistent || notification.type === 'error'
              ? null
              : autoHideDuration
          }
          message={notification.content}
          action={
            <>
              {notification.action}
              {notification.showSpinner && (
                <CircularProgress size={18} color="inherit" />
              )}
              {notification.showCloseButton && (
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => gracefullyRemoveNotification(notification.id)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </>
          }
          style={{ bottom: 24 + index * (48 + 16) }} // NOTE sx doesn't work here
          onClose={(_event, reason) => handleClose(notification, reason)}
          TransitionComponent={TransitionRight}
          ContentProps={{
            sx: {
              ...notificationTypeToStyle(theme.palette.mode)[notification.type],
              [`& .${snackbarContentClasses.action}`]: {
                gap: 0.5,
                marginRight: 0,
              },
            },
          }}
        />
      ))}
    </>
  );
};

const TransitionRight = (props: SlideProps) => {
  return <Slide {...props} direction="right" />;
};

function notificationTypeToStyle(mode: PaletteMode) {
  return {
    default: {
      backgroundColor: mode === 'light' ? '#313131' : '#FBFBFB',
      color: mode === 'light' ? '#fff' : '#484848',
    },
    warning: {
      backgroundColor: mode === 'light' ? orange[700] : orange[500],
    },
    error: {
      backgroundColor: mode === 'light' ? red[700] : red[500],
    },
  };
}
