import { Box, FormControlLabel, Switch } from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { useDrawing } from '@pn/services/drawing';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { isNil, set } from 'lodash-es';

type Props = {
  featureLive: CanvasFeature | undefined;
};

export const Measurements = ({ featureLive }: Props) => {
  const {
    drawingMode,
    displayMeasurements,
    setDisplayMeasurements,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const isPolyOrCircle =
    featureLive &&
    (featureLive.type === 'poly' || featureLive.type === 'circle');

  const checked = isPolyOrCircle
    ? !isNil(featureLive.measurements)
    : ['line', 'arrow', 'rectangle', 'circle'].includes(drawingMode)
      ? displayMeasurements
      : undefined;

  const handleChange = (display: boolean) => {
    setDisplayMeasurements(display);
    drawingState.displayMeasurements = display;

    if (isPolyOrCircle) {
      const measurements = display
        ? generateFeatureMeasurements(featureLive, { local: false })
        : undefined;

      set(
        drawingState.featuresSelected[featureLive.id],
        'measurements',
        measurements
      );
      set(drawingState.features[featureLive.id], 'measurements', measurements);
      redraw();

      historyManager.add(drawingState);
    }
  };

  if (isNil(checked)) return null;

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={(e) => handleChange(e.target.checked)}
          />
        }
        label="Display measurements"
        slotProps={{
          typography: {
            variant: 'body2',
            color: 'textSecondary',
          },
        }}
      />
    </Box>
  );
};
